import axios from 'axios';
import { getLoansQuery } from '../queries';
import parseError from '../errors/utils';

const getLoansByIds = async ({ ids }) => {
    try {
        if (ids) {
            const loans = await axios.get(getLoansQuery({ ids }));
            return { loans: loans?.data };
        }
    } catch (error) {
        return { error: parseError({ error }) };
    }
};

export default getLoansByIds;
