import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import MomentUtils from '@date-io/moment';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getCloseDayDetails, getCloseMonthDetails, setError } from '../store/actions';
import moment from 'moment-timezone';
import { AbilityContext } from '../auth/permissions';

const RestrictedDatePicker = (props) => {
    const ignoreLockPeriod = props?.ignoreLockPeriod || false;
    const dispatch = useDispatch();
    const [lockedDays, setLockedDays] = useState([]);
    const [lockedDaysList, setLockedDaysList] = useState([]);

    const ability = useContext(AbilityContext);
    const canAccessClosedMonth = ability?.can('mutate', 'close_month');

    useEffect(() => {
        let isMounted = true;
        const fetchList = () => {
            if (canAccessClosedMonth) {
                dispatch(getCloseMonthDetails({}))
                    .then(({ payload }) => {
                        if (!isMounted) return;
                        setLockedDays(payload.closedMonth.filter((month) => month.is_locked !== null));
                    })
                    .catch((error) => {
                        if (!isMounted) return;
                        dispatch(setError(error));
                    });
            } else {
                dispatch(getCloseDayDetails({}))
                    .then(({ payload }) => {
                        if (!isMounted) return;
                        setLockedDays(payload.closeDays);
                    })
                    .catch((error) => {
                        if (!isMounted) return;
                        dispatch(setError(error));
                    });
            }
        };
        fetchList();

        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        setLockedDaysList(lockedDays.filter((day) => day.is_locked).map((day) => moment(day.date).format('YYYY-MM-DD')));
    }, [lockedDays]);

    const checkIfDayLocked = (date) => {
        return lockedDaysList.includes(date.format('YYYY-MM-DD'));
    };

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <DateTimePicker
                shouldDisableDate={!ignoreLockPeriod && checkIfDayLocked}
                leftArrowButtonProps={{
                    classes: {
                        root: 'fs-ignore-rage-clicks',
                    },
                }}
                rightArrowButtonProps={{
                    classes: {
                        root: 'fs-ignore-rage-clicks',
                    },
                }}
                {...props}
            />
        </MuiPickersUtilsProvider>
    );
};

export default React.memo(RestrictedDatePicker);
