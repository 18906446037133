import axios from 'axios';
import Split from '../split';
import { resolveId } from '../../utils';
import { createRouteSessionQuery } from './queries';
import { buildExpressQuery } from './queries/builders';

const addRouteSession = (model) =>
    new Promise((resolve, reject) => {
        const useExpressEndpoint = Split.getBooleanTreatment('use_express_route_session_endpoint');

        const query = createRouteSessionQuery({ model, ...(useExpressEndpoint && { queryBuilder: buildExpressQuery }) });
        const request = axios.post(query.url, { ...query.body });

        request
            .then((response) => {
                resolve({ data: resolveId(response.data) });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to add route session'));
            });
    });

export default addRouteSession;
