import axios from 'axios';
import { deleteLoanQuery } from '../queries';

const deleteLoan = async ({ loanId }) => {
    const query = deleteLoanQuery({ id: loanId });
    const res = await axios.delete(query);
    return res;
};

export default deleteLoan;
