import axios from 'axios';
import Split from '../split';
import { resolveId } from '../../utils';
import { editDropoffQuery } from './queries';
import { buildExpressQuery } from './queries/builders';

const editDropoff = (model, key) =>
    new Promise((resolve, reject) => {
        const useExpressEndpoint = Split.getBooleanTreatment('use_express_dropoff_endpoint');

        const query = editDropoffQuery({ key, model, ...(useExpressEndpoint && { queryBuilder: buildExpressQuery }) });
        const requestMethod = useExpressEndpoint ? axios.put : axios.patch;

        const request = requestMethod(query.url, { ...query.body });

        request
            .then((response) => {
                resolve({ data: resolveId(response.data) });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to edit dropoff'));
            });
    });

export default editDropoff;
