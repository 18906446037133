import { AbilityBuilder, PureAbility } from '@casl/ability';
import { PROCESSED_FLUID_REGIONS } from '../../../../constants/bulkRegion';
import Region from '../../../../constants/region';

const ADJUSTMNENT_REGIONS = [Region.PRAIRIE, Region.UDA];
const HIDE_PICKUP_FROM_DESTINATION_REGIONS = [Region.PRAIRIE];
const HAULER_CAN_MUTATE_REGIONS = [Region.UDA];

function ProcessedFluidPermissions(user) {
    const { can, cannot, rules } = new AbilityBuilder(PureAbility);
    const {
        role,
        data: { region, id, processor_id },
    } = user;

    if (role === 'admin') {
        can('mutate', 'adjustment');
        can('mutate', 'pickup');
        can('mutate', 'dropoff');
    }

    // Can Create Pickup if origin plant or subuser of origin plant
    can('mutate', 'pickup', { 'origin_processor_id._id': id });
    can('mutate', 'pickup', { 'origin_processor_id._id': processor_id });

    if (HAULER_CAN_MUTATE_REGIONS.includes(region)) {
        if (role === 'transport') {
            can('mutate', 'pickup');
            can('mutate', 'dropoff');
        }
    }

    // Can Create Dropoff if destination plant or subuser of destination plant
    can('mutate', 'dropoff', { 'destination_processor_id._id': id });
    can('mutate', 'dropoff', { 'destination_processor_id._id': processor_id });

    // Everyone can view pickups and dropoffs by default
    can('view', 'pickup');
    can('view', 'dropoff');

    if (ADJUSTMNENT_REGIONS.includes(region)) {
        can('view', 'adjustment');

        // Can mutate adjustment if destination plant or subuser of destination plant
        can('mutate', 'adjustment', { 'destination_processor_id._id': id });
        can('mutate', 'adjustment', { 'destination_processor_id._id': processor_id });

        if (HIDE_PICKUP_FROM_DESTINATION_REGIONS.includes(region)) {
            // If logged in as the route session destination, cannot view a pickup
            cannot('view', 'pickup', { 'destination_processor_id._id': id, dropoff_id: null });
            cannot('view', 'pickup', { 'destination_processor_id._id': processor_id, dropoff_id: null });
        }
    }

    if (PROCESSED_FLUID_REGIONS.includes(region)) {
        can('view', 'new_processed_fluids');
    }

    return rules;
}

export default ProcessedFluidPermissions;
