import axios from 'axios';
import { getLoanPaymentsQuery } from '../queries';
import parseError from '../errors/utils';

const getLoanPaymentById = async (id) => {
    try {
        const loanPayment = await axios.get(getLoanPaymentsQuery({ id }));
        return { loanPayment: loanPayment?.data?.[0] };
    } catch (error) {
        return { error: parseError({ error }) };
    }
};

export default getLoanPaymentById;
