import React, { useEffect, useState } from 'react';
import { Card, Collapse, CardHeader, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { FormLabel, FormControl, FormControlLabel, FormGroup, FormHelperText, Checkbox, Typography } from '@material-ui/core';
import getPermissions from './config/index';
import Split from '../../split';

/*  For an in-depth explanation of the PermissionSelector process. 
    Visit the Milkmoovement-Fuse Wiki on Github.    */
const PermissionSelector = ({ permissionType, handlePermissionChange, selectedValues, disabled, showError, hideHeader }) => {
    const [selectAll, setSelectAll] = useState(false);
    const { permissionGroups, requiredPermissions } = getPermissions(permissionType);
    const [selectedPermissions, setSelectedPermissions] = useState(new Set([...selectedValues, ...requiredPermissions]));
    let totalPermissions = 0;
    const error = !selectedPermissions.size;

    const isRequired = (permission) => requiredPermissions.has(permission);
    const isSelected = (permission) => selectedPermissions.has(permission);

    const [groupState, setGroupState] = useState(
        permissionGroups.reduce(
            (state, { label, permissions }) => ({
                ...state,
                [label]: permissions.reduce(
                    (acc, { value }) => {
                        totalPermissions += 1;
                        return {
                            ...acc,
                            selected: acc.selected + (isSelected(value) || isRequired(value)),
                            required: acc.required + isRequired(value),
                        };
                    },
                    { selected: 0, required: 0, checked: false, open: false, total: permissions.length }
                ),
            }),
            {}
        )
    );

    useEffect(() => {
        handlePermissionChange(selectedPermissions);
        setSelectAll(selectedPermissions.size === totalPermissions);
    }, [groupState]);

    const updateGroupState = (label, selected) => {
        setGroupState((prev) => ({
            ...prev,
            [label]: { ...groupState[label], selected, checked: selected === groupState[label].total },
            // force-wrap
        }));
    };

    const handleToggleGroup = (label, open) => (event) => {
        setGroupState((prev) => ({ ...prev, [label]: { ...prev[label], open: !open } }));
    };

    const selectPermission = (label, permission, checked, update = true) => {
        const { selected } = groupState[label];
        const permissions = selectedPermissions;
        if (checked) permissions.add(permission);
        else if (!isRequired(permission)) permissions.delete(permission);
        setSelectedPermissions(permissions);
        if (update) updateGroupState(label, checked ? selected + 1 : !isRequired(permission) ? selected - 1 : selected);
    };

    const selectGroup = (g, checked) => {
        g.permissions.forEach(({ value }) => {
            selectPermission(g.label, value, checked, false);
        });
        updateGroupState(g.label, checked ? groupState[g.label].total : groupState[g.label].required);
    };

    const handleSelectPermission = (label, permission) => (event) => {
        selectPermission(label, permission, !!event.target.checked);
    };

    const handleSelectGroup = (g) => (event) => {
        selectGroup(g, !!event.target.checked);
    };

    const handleSelectAll = () => (event) => {
        permissionGroups.forEach((g) => selectGroup(g, !!event.target.checked));
    };

    return (
        <FormControl error={error && showError} component="fieldset">
            {!!hideHeader && (
                <FormLabel className="flex flex-row justify-center">
                    <Typography variant="h6" className="mt-16 mb-16 justify-center">
                        Permissions
                    </Typography>
                </FormLabel>
            )}
            <FormControlLabel
                key="label-selectAll"
                control={<Checkbox checked={selectAll} onChange={handleSelectAll()} />}
                label="Select All"
                // force-wrap
            />
            <Card>
                {permissionGroups.map((g) => {
                    const { label } = g;
                    const { selected, total, open, checked } = groupState[label];
                    return (
                        <div style={{ boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.1)', paddingBottom: '1px' }}>
                            <CardHeader
                                titleTypographyProps={{ variant: 'body1' }}
                                title={`${label}`}
                                action={
                                    <div className="flex flex-row justify-center">
                                        <Typography variant="body1" style={{ marginTop: 5 }}>
                                            {selected}/{total} selected
                                        </Typography>
                                        <IconButton onClick={handleToggleGroup(label, open)} aria-label="expand" size="small">
                                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    </div>
                                }
                            />
                            <Collapse in={groupState[g.label].open} timeout="auto" unmountOnExit>
                                <FormGroup>
                                    <FormControlLabel
                                        style={{ backgroundColor: '#FAFAFA', paddingLeft: '15px', marginRight: 0 }}
                                        key={`${label}-selectAll`}
                                        disabled={disabled}
                                        control={<Checkbox checked={checked} onChange={handleSelectGroup(g)} />}
                                        label="Select All"
                                        // force-wrap
                                    />
                                    {g.permissions.map((p, index) => {
                                        const { value } = p;
                                        const backgroundColor = index % 2 === 0 ? '#FFFFFF' : '#FAFAFA';
                                        return (
                                            <FormControlLabel
                                                style={{ backgroundColor, paddingLeft: '15px', marginRight: 0 }}
                                                key={`${p.label}-select`}
                                                disabled={isRequired(value)}
                                                control={
                                                    <Checkbox
                                                        checked={isSelected(value)}
                                                        onChange={handleSelectPermission(label, value)}
                                                        value={value}
                                                        // force-wrap
                                                    />
                                                }
                                                label={p.label}
                                                // force-wrap
                                            />
                                        );
                                    })}
                                </FormGroup>
                            </Collapse>
                        </div>
                    );
                })}
            </Card>
            {error && showError && <FormHelperText>* Please select at least one permission.</FormHelperText>}
        </FormControl>
    );
};

export default PermissionSelector;
