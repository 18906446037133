import axios from 'axios';
import { editLoanPaymentQuery } from '../queries';
import parseError from '../errors/utils';

const editLoanPayment = async (model, id) => {
    try {
        const query = editLoanPaymentQuery({ model, id });
        const request = await axios.put(query.url, { ...query.body });
        return request.data;
    } catch (error) {
        return { error: parseError({ error }) };
    }
};

export default editLoanPayment;
