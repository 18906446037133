import Environment from '../../Environment';
import MockSplit from '../../MockSplit';
import stages from '../stages';
import Region from '../../../constants/region';

const prod = new Environment({
    name: stages.PRODUCTION,
    regions: [],
});

const demo = new Environment({
    ...prod,
    name: stages.DEMO,
});

const staging = new Environment({
    name: stages.STAGING,
    regions: [Region.PRAIRIE],
});

const local = new Environment({
    ...staging,
    name: stages.LOCAL,
});

const dev = new Environment({
    ...staging,
    name: stages.DEV,
});

const can_create_stock_holder = new MockSplit({
    name: 'can_create_stock_holder',
    environments: [local, dev, staging, demo, prod],
});

export default can_create_stock_holder;
