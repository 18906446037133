import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Box, Button, CircularProgress, Divider, FormControl, FormGroup, FormControlLabel, Grid, InputAdornment, Switch, TextField, Typography, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Alert } from '@mui/material';
import axios from 'axios';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import Formsy from 'formsy-react';
import _ from 'lodash';
import * as FuseActions from 'app/store/actions';
import * as Actions from '../../store/actions';
import Region from '../../constants/region';
import { EQUITY_REGIONS, STATUS_REGIONS } from '../../constants/bulkRegion';
import { downloadBulkChart, getBulkChartsForProducer, getFileURL, uploadBulkChart } from '../../repository';
import { arrayToMap, formatUserErrorMessage, generateTestId, getAPIHost, getDefaultLatitude, getDefaultLongitude, getGoogleMapsURL } from '../../../utils';
import { milkCategory } from '../form-components/ReactHookProducerFormSelects';
import { SelectSearchFormsy } from '../../../@fuse/components/formsy';
import { TextFieldFormsy } from '@fuse';
import { LocalizationConsumer } from '../../localization/LocalizationContext';
import SearchableSelect from '../../custom-widgets/SearchableSelect';
import UserAlreadyExistsWarningDialog from '../../custom-widgets/dialogs/UserAlreadyExistsWarningDialog';
import GeofenceManager from '../geofences/maps/GeofenceManager/GeofenceManager';
import Split from '../../split';

class EditProducerForm extends Component {
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
        // eslint-disable-next-line react/state-in-constructor
        this.state = {
            canSubmit: false,
            alert: null,
            warning: null,
            latitude: getDefaultLatitude(props.region),
            longitude: getDefaultLongitude(props.region),
            haulingCompanies: [],
            selectedType: 'milk',
            state_number: null,
            hauling_type: '',
            selfHaul: false,
            payment_method: null,
            equity_payment_method: null,
            equity_id: null,
            territory: null,
            status: 'Active',
            start_date: null,
            reason_added: '',
            producer_relationship: null,
            has_voting_role: false,
            out_of_business_date: null,
            out_of_business_reason: '',
            selectedBulkTank: 1,
            rBSTdate: null,
            isFairlifeProducer: null,
            fileToLoad: '',
            deleteDialogOpen: false,
            pickupType: null,
            numOfTanks: '1',
            numOfLocations: '',
            tanks: [{ tank_number: '1', tank_capacity: 0, tank_location: '' }],
            tankLocations: [],
            locations: [],
            district: null,
            jurisdiction_name: null,
            defaultHauler: null,
            zone: null,
            bulk_charts: [],
            newTanks: [{ tank_number: '1', tank_capacity: 0, tank_location: '' }],
            zones: [
                {
                    name: 'aSoCalSnf',
                    value: 'Pay Zone A SO CAL, SNF Quota RQA -0',
                },
                {
                    name: 'aSoCal',
                    value: 'Pay Zone A SO CAL',
                },
                {
                    name: 'bKernSnf',
                    value: 'Pay Zone B Kern-Pay Agreement, SNF Quota RQA 0.20',
                },
                {
                    name: 'cTulareSnfA',
                    value: 'Pay Zone C Tulare, SNF Quota RQA -0.2-Pay Agreement',
                },
                {
                    name: 'cTulareSnfB',
                    value: 'Pay Zone C Tulare, SNF Quota RQA -0.11',
                },
                {
                    name: 'dSoCalSnf',
                    value: 'Pay Zone D NO CAL, SNF Quota RQA -0.11',
                },
            ],
            milkCategory: null,
            permit_number: null,
            geofences: [],
            producerName: null,
        };
    }

    componentDidMount() {
        const { editData, haulingCompanies, getHaulingListDetails, region } = this.props;

        const pickupType = [Region.CDI, Region.LEGACY].includes(region) ? 'dip' : region === Region.UDA ? 'scale' : null;
        if (pickupType) this.setState({ pickupType });

        if (editData) {
            this.setState({
                status: editData.status,
                latitude: editData.latitude,
                longitude: editData.longitude,
                jurisdiction_name: editData.jurisdiction_name,
                zone: editData.zone,
                selfHaul: editData.self_haul,
                hauling_type: editData.hauling_type,
                has_voting_role: editData.has_voting_role,
                defaultHauler: editData.default_hauling_company,
                district: editData.route_region,
                rBSTdate: editData.rBST_sign_up_date,
                start_date: editData?.start_date || null,
                reason_added: editData?.reason_added || null,
                out_of_business_date: editData?.out_of_business_date || null,
                out_of_business_reason: editData?.out_of_business_reason || null,
                isFairlifeProducer: editData?.fairlife_producer || null,
                pickupType: editData?.default_measurement_method || null,
                equity_id: editData?.equity_id || null,
                tanks: editData.tanks ? editData.tanks : [],
                newTanks: editData.tanks ? editData.tanks : [],
                tankLocations: editData.tanks ? _.uniq(editData.tanks.map(({ tank_location }) => tank_location)) : [],
                locations: editData.tanks ? _.uniq(editData.tanks.map(({ tank_location }) => tank_location)) : [],
                numOfTanks: editData.tank ? editData.tank : 1,
                milkCategory: editData.milk_category,
            });
        }
        if (editData) {
            getBulkChartsForProducer(editData.id).then((charts) => this.setState({ bulk_charts: charts }));
            getHaulingListDetails().then((value) => {
                this.setState({ haulingCompanies: this.transformHaulingNames(value.payload.haulingCompanies) });
            });
        } else {
            getHaulingListDetails().then((value) => {
                this.setState({ haulingCompanies: this.transformHaulingNames(value.payload.haulingCompanies) });
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { editData } = this.props;

        const { fileToLoad } = this.state;
        if (prevProps.editData !== editData) {
            if (editData) {
                this.setState({
                    latitude: editData.latitude,
                    longitude: editData.longitude,
                    rBSTdate: editData.rBST_sign_up_date,
                    out_of_business_reason: editData?.out_of_business_reason || null,
                    out_of_business_date: editData?.out_of_business_date || null,
                    isFairlifeProducer: editData?.fairlife_producer || null,
                    pickupType: editData.default_measurement_method,
                });
            }
        }

        if (prevState.fileToLoad !== fileToLoad) {
            document.getElementById('bulkChart').click();
        }
    }

    transformHaulingNames = (details) => details.filter((value) => !value.deleted_at).map((value) => ({ label: value.name, value: value.id }));

    disableButton = () => {
        this.setState({ canSubmit: false });
    };

    enableButton = () => {
        this.setState({ canSubmit: true });
    };

    navigateToListUsers = () => {
        const { history } = this.props;
        history.replace({ pathname: '/list-producers' });
    };

    onSubmit = (model) => {
        const {
            editData,
            latitude,
            longitude,
            showMessage,
            editUser,
            addProducer,
            selectedType,
            region,
            rBSTdate,
            status,
            start_date,
            reason_added,
            out_of_business_date,
            out_of_business_reason,
            isFairlifeProducer,
            jurisdiction_name,
            district,
            tanks,
            geofences,
            // wrap
        } = { ...this.state, ...this.props };
        const submitModel = { ...model };

        submitModel.rBST_sign_up_date = !!rBSTdate ? moment(rBSTdate).format() : undefined;
        delete submitModel.location;
        delete submitModel.bulk_chart_tank;
        submitModel.daily_fluid_milk_quota = submitModel.daily_fluid_milk_quota ? parseInt(submitModel.daily_fluid_milk_quota) : 0;
        submitModel.latitude = latitude.toString();
        submitModel.longitude = longitude.toString();
        submitModel.type_of_fluid = editData ? _.lowerCase(editData.type_of_fluid) : _.lowerCase(selectedType);
        submitModel.tank = submitModel.num_of_tank.toString();
        submitModel.hauling_type_stop_limit = submitModel.hauling_type_stop_limit ? parseInt(submitModel.hauling_type_stop_limit) : null;
        submitModel.status = status;
        submitModel.start_date = !!start_date ? moment(start_date).format() : null;
        submitModel.reason_added = !!reason_added ? reason_added : null;
        submitModel.out_of_business_date = !!out_of_business_date ? moment(out_of_business_date).format() : null;
        submitModel.out_of_business_reason = !!out_of_business_reason ? out_of_business_reason : null;

        delete submitModel.num_of_tank;
        delete submitModel.num_of_location;
        delete submitModel.tank_number;
        delete submitModel.tank_location;

        submitModel.route_region = district;
        submitModel.jurisdiction_name = jurisdiction_name;

        if ([Region.UDA, Region.LEGACY, Region.CDI].includes(region)) {
            submitModel.tanks = tanks || [];
        }

        if (region === Region.UDA) {
            submitModel.fairlife_producer = !!isFairlifeProducer;
        }

        if (geofences.length > 0) {
            const fence = geofences[0];
            fence.name = `${submitModel.name} - ${fence.type}`;
            delete fence._id;
            delete fence.deleted_at;
            submitModel.geofence_payload = fence;

            // Update the user model to reflect the new geofence
            submitModel.latitude = fence.center.coordinates[1].toString();
            submitModel.longitude = fence.center.coordinates[0].toString();
            submitModel.geofence_radius = fence.radius_meters;
        }

        if (editData) {
            delete submitModel.username;
            delete submitModel.email;
            if (editData?.milk_category) delete submitModel.milk_category;

            editUser(submitModel, editData.id)
                .then((response) => {
                    showMessage({ message: 'Successfully Edited Producer.' });
                    this.setState({ alert: null });
                    this.navigateToListUsers();
                })
                .catch((err) => {
                    showMessage({ message: formatUserErrorMessage(err, 'Producer', 'edit') });
                });
        } else {
            delete submitModel.geofence_payload;
            addProducer(submitModel)
                .then((response) => {
                    showMessage({ message: 'Successfully Added Producer.' });
                    this.setState({ alert: null });
                    if (response?.payload?.data?.warning) {
                        this.setState({ warning: response.payload.data.warning });
                    } else {
                        this.navigateToListUsers();
                    }
                })
                .catch((err) => {
                    showMessage({ message: 'Unable to create a new Producer' });
                    if (err.message) {
                        this.setState({ alert: err.message });
                    }
                });
        }
    };

    deleteBulkChart = () => {
        const { editData, showMessage, history, selectedBulkTank, bulk_charts } = { ...this.state, ...this.props };

        const bulkCharts = bulk_charts;
        bulkCharts[selectedBulkTank - 1] = '';

        axios
            .patch(`${getAPIHost()}/users/${editData.id}`, { bulk_charts: bulkCharts })
            .then(() => {
                showMessage({ message: 'Successful. This may take a few seconds to reflect on your page.' });
                history.goBack();
            })
            .catch((err) => {
                showMessage({ message: 'There was a problem during deletion. Please contact support.' });
            });
    };

    downloadChart = ({ editData, showMessage, selectedBulkTank }) => {
        downloadBulkChart(editData.id, selectedBulkTank)
            .then((result) => {
                getFileURL(`BulkCharts/${selectedBulkTank}/${result.data}`)
                    .then((file) => {
                        this.setState({ fileToLoad: file });
                    })
                    .catch((error) => {
                        showMessage({ message: 'There was a problem downloading your file. Please contact support.' });
                    });
            })
            .catch((err) => {
                showMessage({ message: 'There was a problem downloading your file. Please contact support.' });
            });
    };

    downloadTemplateChart = ({ showMessage }) => {
        getFileURL('BulkCharts/1/Bulk_Chart_Template.xlsx')
            .then((result) => {
                this.setState({ fileToLoad: result });
            })
            .catch((error) => {
                showMessage({ message: 'There was a problem downloading your file. Please contact support.' });
            });
    };

    uploadNewChart = ({ editData, showMessage, history, selectedBulkTank }) => {
        const obj = {
            file: this.fileInput.current.files[0],
            producer_id: editData.id,
            bulk_tank: selectedBulkTank,
        };

        if (obj.file) {
            if (obj.file.name.slice(-4) !== '.csv' && obj.file.name.slice(-5) !== '.xlsx') {
                showMessage({ message: 'Please provide a valid .csv or .xlsx file.' });
                return;
            }

            const formData = new FormData();
            formData.append('file', obj.file);
            formData.append('producer_id', obj.producer_id);
            formData.append('bulk_tank', obj.bulk_tank);

            uploadBulkChart(formData)
                .then(() => {
                    showMessage({ message: `Successfully updated ${editData.name} Bulk Tank Chart!` });
                    history.goBack();
                })
                .catch(() => {
                    // console.warn('ERROR: ', err);
                    showMessage({ message: 'There was a problem uploading your file. Please contact support.' });
                });
        }
    };

    handleTypeChange = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    handlePaymentMethodChange = (event) => {
        this.setState({ payment_method: event.target.value });
    };

    handleEquityPaymentMethodChange = (event) => {
        this.setState({ equity_payment_method: event.target.value });
    };

    handleTerritoryChange = (event) => {
        this.setState({ territory: event.target.value });
    };

    handleStatusChange = (event) => {
        if (event.target.value === 'Active') {
            this.setState({
                status: event.target.value,
                out_of_business_date: null,
                out_of_business_reason: null,
            });
        } else {
            this.setState({ status: event.target.value });
        }
    };

    handleReasonAddedChange = (event) => {
        this.setState({ reason_added: event.target.value });
    };

    handleBusinessChange = (event) => {
        this.setState({ out_of_business_reason: event.target.value });
    };

    handleRelationshipChange = (event) => {
        this.setState({ producer_relationship: event.target.value });
    };

    handleTankChange = (numOfTanks) => {
        const { region } = this.props;
        const { tanks } = this.state;

        if (region === Region.UDA) {
            this.setState({ numOfTanks: numOfTanks.toString() });
            return;
        }

        const numOfTanksToAdd = Math.abs(numOfTanks - tanks.length);

        // add logic
        if (numOfTanks > tanks.length) {
            let newTanks = _.sortBy(tanks, 'tank_number');
            for (let i = 0; i < numOfTanksToAdd; i++) {
                const newTank = {
                    tank_number: newTanks[newTanks.length - 1] ? (parseInt(newTanks[newTanks.length - 1].tank_number) + 1).toString() : (i + 1).toString(),
                    tank_capacity: 0,
                    tank_location: '',
                };
                newTanks = [...newTanks, newTank];
            }
            this.setState({
                tanks: newTanks,
                newTanks,
            });
        } else {
            const newTanks = _.sortBy(tanks, 'tank_number');
            this.setState({ tanks: newTanks.filter((element, index) => index < numOfTanks), newTanks: newTanks.filter((element, index) => index < numOfTanks) });
        }
    };

    handleAddTank = () => {
        const { tanks } = this.state;
        this.setState({ tanks: [...tanks, { tank_number: '', tank_location: '', tank_capacity: 0 }] });
    };

    handleRemoveTank = () => {
        const { tanks } = this.state;
        if (tanks.length > 1) {
            this.setState({ tanks: tanks.filter((element, index) => index < tanks.length - 1) });
        }
    };

    handleTankNumberChange = (tank, i) => {
        const { tanks } = this.state;
        tanks[i].tank_number = tank.value;
        this.setState({ tanks });
    };

    handleTankLocationChange = (value, i) => {
        const { tanks } = this.state;
        tanks[i].tank_location = value.target.value;
        this.setState({ tanks });
    };

    handleBulkTankChange = (event) => {
        this.setState({ selectedBulkTank: event.target.value });
    };

    handleChangeTankSelection = (event, value, location) => {
        const { editData, newTanks } = { ...this.state, ...this.props };

        const tanksInOtherLocations = newTanks.filter((tank) => tank.tank_location !== location);
        const tanksInLocationToUpdate = newTanks.filter((tank) => tank.tank_location === location);
        const tanksNotRemove = [];

        if (tanksInLocationToUpdate.length < value.length || tanksInLocationToUpdate.length === 0) {
            // add logic
            if (editData) {
                const newTank = {
                    tank_number: value[value.length - 1],
                    tank_capacity: 0,
                    tank_location: location.toString(),
                };
                const updatedTanks = newTanks.filter((tank) => tank.tank_number !== newTank.tank_number);
                this.setState({ newTanks: _.sortBy(updatedTanks.concat(newTank), 'tank_number') });
            } else {
                const tankToUpdate = newTanks.filter((tank) => tank.tank_number === value[value.length - 1]);
                if (tankToUpdate[0]) {
                    tankToUpdate[0].tank_location = location.toString();
                }
                const tanksToNotUpdate = newTanks.filter((tank) => tank.tank_number !== value[value.length - 1]);
                this.setState({ newTanks: _.sortBy(tanksToNotUpdate.concat(tankToUpdate), 'tank_number') });
            }
        } else {
            // remove logic
            for (let i = 0; i < value.length; i++) {
                const tanksThatShouldStayTheSame = tanksInLocationToUpdate.filter((tank) => tank.tank_number === value[i]);

                if (tanksThatShouldStayTheSame[0]) {
                    tanksNotRemove.push(tanksThatShouldStayTheSame[0]);
                }
            }
            this.setState({ newTanks: _.sortBy(tanksInOtherLocations.concat(tanksNotRemove), 'tank_number') });
        }
    };

    handleDateChange = (date) => {
        this.setState({ rBSTdate: date });
    };

    handleBusinessDateChange = (date) => {
        this.setState({ out_of_business_date: date });
    };

    handleStartDateChange = (date) => {
        this.setState({ start_date: date });
    };

    handleZoneChange = (event, value) => {
        this.setState({ zone: value });
    };

    handleDistrictChange = (event, value) => {
        this.setState({ district: event.target.value });
    };

    handleJurisdictionChange = (event, value) => {
        this.setState({ jurisdiction_name: event.target.value });
    };

    handleHaulingChange = (event) => {
        this.setState({ defaultHauler: event.target.value });
    };

    handleHaulingTypeChange = (event) => {
        this.setState({ hauling_type: event.target.value });
        if (event.target.value) this.setState({ selfHaul: false });
    };

    handleSelfHaul = (event) => {
        this.setState({ selfHaul: event.target.value });
        if (event.target.value) this.setState({ hauling_type: '' });
    };

    handleVotingRole = (event) => {
        this.setState({ has_voting_role: event.target.value });
    };

    handleToggleChange = (name) => (event) => {
        this.setState({ [name]: event.target.checked });
    };

    handleEditCircleFromMap = (newGeofences) => {
        this.setState({ geofences: [...newGeofences] });
    };

    render() {
        const {
            canSubmit,
            fileToLoad,
            latitude,
            longitude,
            bulk_charts,
            selectedType,
            hauling_type,
            payment_method,
            equity_payment_method,
            territory,
            producer_relationship,
            status,
            start_date,
            reason_added,
            out_of_business_date,
            out_of_business_reason,
            selectedBulkTank,
            deleteDialogOpen,
            rBSTdate,
            isFairlifeProducer,
            newTanks,
            tanks,
            locations,
            numOfTanks,
            haulingCompanies,
            district,
            jurisdiction_name,
            zone,
            defaultHauler,
            selfHaul,
            has_voting_role,
            zones,
        } = this.state;
        const { editData, region } = this.props;
        const tankList = new Array(numOfTanks ? Number(numOfTanks) : 0).fill('').map((e, i) => ({ value: `${i + 1}`, name: `${i + 1}` }));

        return (
            <LocalizationConsumer>
                {(localization) => (
                    <div className="min-w-3/4 max-w-3/4">
                        <UserAlreadyExistsWarningDialog open={!!this.state.warning} onClose={() => this.navigateToListUsers()} message={this.state.warning} />

                        <Divider />

                        <Formsy
                            onValidSubmit={this.onSubmit}
                            onValid={this.enableButton}
                            ƒ={this.disableButton}
                            /* eslint-disable-next-line no-return-assign */
                            ref={(form) => (this.form = form)}
                            className="flex flex-col justify-center"
                        >
                            {this.state.alert && (
                                <Alert className="mt-32 mb-16" color="error">
                                    {this.state.alert}
                                </Alert>
                            )}

                            <FormControl className="w-full h-full">
                                <SelectSearchFormsy
                                    className="my-16"
                                    name="type_of_fluid"
                                    label="Type of Fluid"
                                    options={[
                                        { value: 'milk', name: 'Milk' },
                                        { value: 'cream', name: 'Cream' },
                                    ]}
                                    value={editData ? _.lowerCase(editData.type_of_fluid) : selectedType}
                                    onChange={(value) => this.handleTypeChange(value)}
                                    variant="standard"
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    disabled={!!editData}
                                />
                            </FormControl>
                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="name"
                                placeholder="Farm Name"
                                label="Farm Name"
                                value={editData ? editData.name : ''}
                                onChange={(event) => this.setState({ producerName: event.target.value })}
                                validations={{ minLength: 2 }}
                                validationErrors={{ minLength: 'Min character length is 2' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                storefront
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                variant="outlined"
                            />
                            {[Region.CDI, Region.LEGACY].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="license_number"
                                    placeholder="Producer ID"
                                    label="Producer ID"
                                    value={editData ? editData.license_number : ''}
                                    ValidationErrors={{ minLength: 'Min character length is 2' }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="text-20 material-icons" color="action">
                                                    person
                                                </i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    required
                                    variant="outlined"
                                />
                            )}
                            {[Region.UDA].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="btu"
                                    placeholder="BTU"
                                    label="BTU"
                                    value={editData ? editData.btu : ''}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="text-20 material-icons" color="action">
                                                    list_alt
                                                </i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}
                            {Split.getBooleanTreatment('can_access_equity_pages') && (
                                <>
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="text"
                                        name="equity_id"
                                        placeholder="Equity ID"
                                        label="Equity ID"
                                        value={editData ? editData.equity_id : ''}
                                        validationErrors={{ minLength: 'Min character length is 2' }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        list_alt
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                </>
                            )}
                            {[Region.CDI].includes(region) && (
                                <>
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="text"
                                        name="bank_name"
                                        placeholder="Bank Name"
                                        label="Bank Name"
                                        value={editData ? editData.bank_name : ''}
                                        validationErrors={{ minLength: 'Min character length is 2' }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        person
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="text"
                                        name="account_number"
                                        placeholder="AP Account"
                                        label="AP Account"
                                        value={editData ? editData.account_number : ''}
                                        validationErrors={{ minLength: 'Min character length is 2' }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        list_alt
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="text"
                                        name="gl_number"
                                        placeholder="GL Number"
                                        label="GL Number"
                                        value={editData ? editData.gl_number : ''}
                                        validationErrors={{ minLength: 'Min character length is 2' }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        list_alt
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="number"
                                        name="state_number"
                                        placeholder="State Number"
                                        label="State Number"
                                        value={editData ? editData.state_number : ''}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        list_alt
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                    <SelectSearchFormsy
                                        className="my-16"
                                        name="payment_method"
                                        label="Payment Method"
                                        options={[
                                            { value: 'check', name: 'Check' },
                                            { value: 'eft', name: 'EFT' },
                                            { value: 'internal', name: 'Internal' },
                                        ]}
                                        value={editData ? editData.payment_method : payment_method}
                                        onChange={(value) => this.handlePaymentMethodChange(value)}
                                        variant="outlined"
                                    />
                                    {EQUITY_REGIONS.includes(region) && (
                                        <SelectSearchFormsy
                                            className="my-16"
                                            name="equity_payment_method"
                                            label="Equity Payment Method"
                                            options={[
                                                { value: 'check', name: 'Check' },
                                                { value: 'eft', name: 'EFT' },
                                            ]}
                                            value={editData ? editData.equity_payment_method : equity_payment_method}
                                            onChange={(value) => this.handleEquityPaymentMethodChange(value)}
                                            variant="outlined"
                                        />
                                    )}
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="text"
                                        name="btu"
                                        placeholder="BTU"
                                        label="BTU"
                                        value={editData ? editData.btu : ''}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        list_alt
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="text"
                                        name="county_id"
                                        placeholder="County ID"
                                        label="County ID"
                                        value={editData ? editData.county_id : ''}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        list_alt
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                    <SelectSearchFormsy
                                        className="my-16"
                                        name="territory"
                                        label="Territory"
                                        options={[
                                            { value: 'Field Area 1', name: 'Field Area 1' },
                                            { value: 'Field Area 2', name: 'Field Area 2' },
                                            { value: 'Field Area 3', name: 'Field Area 3' },
                                            { value: 'Field Area 4', name: 'Field Area 4' },
                                            { value: 'Field Area 5', name: 'Field Area 5' },
                                        ]}
                                        value={editData ? editData.territory : territory}
                                        onChange={(value) => this.handleTerritoryChange(value)}
                                        variant="outlined"
                                    />
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="text"
                                        name="district_id"
                                        placeholder="District ID"
                                        label="District ID"
                                        value={editData ? editData.district_id : ''}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        list_alt
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="text"
                                        name="member_contract_name"
                                        placeholder="Member Contract Name"
                                        label="Member Contract Name"
                                        value={editData ? editData.member_contract_name : ''}
                                        validationErrors={{ minLength: 'Min character length is 2' }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        person
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                    <SelectSearchFormsy
                                        className="my-16"
                                        name="producer_relationship"
                                        label="Relationship"
                                        options={[
                                            { value: null, name: 'None' },
                                            { value: 'partner', name: 'Partner' },
                                            { value: 'designated_representative', name: 'Designated Representative' },
                                            { value: 'spouse', name: 'Spouse' },
                                            { value: 'self', name: 'Self' },
                                        ]}
                                        value={editData ? editData.producer_relationship : producer_relationship}
                                        onChange={(value) => this.handleRelationshipChange(value)}
                                        variant="outlined"
                                    />
                                    <SelectSearchFormsy
                                        className="my-16"
                                        variant="outlined"
                                        name="has_voting_role"
                                        label="Voting Role"
                                        options={[
                                            { value: true, name: 'Voter' },
                                            { value: false, name: 'Non Voter' },
                                        ]}
                                        value={has_voting_role}
                                        onChange={this.handleVotingRole}
                                    />
                                </>
                            )}

                            {[Region.LEGACY].includes(region) && (
                                <>
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="number"
                                        name="pooling_number"
                                        placeholder="Pooling Number"
                                        label="Pooling Number"
                                        value={editData ? editData.pooling_number : ''}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        list_alt
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="number"
                                        name="state_number"
                                        placeholder="State Number"
                                        label="State Number"
                                        value={editData ? editData.state_number : ''}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        list_alt
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="number"
                                        name="permit_number"
                                        placeholder="MMPN"
                                        label="MMPN"
                                        value={editData ? editData.permit_number : ''}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        list_alt
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="text"
                                        name="btu"
                                        placeholder="BTU"
                                        label="BTU"
                                        value={editData ? editData.btu : ''}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        list_alt
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                </>
                            )}
                            {STATUS_REGIONS.includes(region) && (
                                <Grid container spacing={4}>
                                    <Grid item xs={4}>
                                        <SelectSearchFormsy
                                            className="my-16 w-full"
                                            name="status"
                                            label="Status *"
                                            options={[
                                                { value: 'Active', name: 'Active' },
                                                { value: 'Inactive', name: 'Inactive' },
                                            ]}
                                            value={this.state.status}
                                            defaultValue={this.state.status}
                                            onChange={(value) => this.handleStatusChange(value)}
                                            required
                                            disableSearch
                                        />
                                    </Grid>
                                    <>
                                        {status === 'Inactive' ? (
                                            <>
                                                <Grid item xs={4}>
                                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                                        <DatePicker className="my-16" data-testid={generateTestId('Out of Business Date', 'date-picker')} fullWidth label="Out of Business Date" name="out_of_business_date" format="MMMM DD, yyyy" value={out_of_business_date} onChange={this.handleBusinessDateChange} variant="outlined" clearable />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <SelectSearchFormsy
                                                        className="my-16 w-full"
                                                        name="out_of_business_reason"
                                                        label="Reason"
                                                        options={[
                                                            { value: 'changed_markets', name: 'Changed Markets' },
                                                            { value: 'combined_herds', name: 'Combined Herds' },
                                                            { value: 'out_of_business', name: 'Out of Business' },
                                                            { value: 'resignation', name: 'Resignation' },
                                                            { value: 'retired', name: 'Retired' },
                                                            { value: 'sold_herd', name: 'Sold Herd' },
                                                        ]}
                                                        value={out_of_business_reason}
                                                        onChange={(value) => this.handleBusinessChange(value)}
                                                    />
                                                </Grid>
                                            </>
                                        ) : (
                                            <>
                                                <Grid item xs={4}>
                                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                                        <DatePicker
                                                            className="my-16"
                                                            data-testid={generateTestId('Start Date', 'date-picker')}
                                                            name="start_date"
                                                            views={['date']}
                                                            label="Start Date"
                                                            format="MMMM DD, yyyy"
                                                            value={start_date}
                                                            disableToolbar
                                                            onChange={this.handleStartDateChange}
                                                            renderInput={(params) => <TextField {...params} helperText={null} />}
                                                            fullWidth
                                                            clearable
                                                            // force-wrap
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextFieldFormsy
                                                        className="my-16"
                                                        type="text"
                                                        name="reason_added"
                                                        label="Reason"
                                                        value={reason_added}
                                                        onChange={this.handleReasonAddedChange}
                                                        // force-wrap
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                </Grid>
                            )}
                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="full_name"
                                placeholder={localization.general.full_name}
                                label={localization.general.full_name}
                                value={editData ? editData.full_name : ''}
                                validations={{ minLength: 2 }}
                                validationErrors={{ minLength: 'Min character length is 2' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                person
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                variant="outlined"
                            />
                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="username"
                                placeholder="Username"
                                label="Username"
                                value={editData ? editData.username : ''}
                                validations={{ ...(!editData && { minLength: 4 }) }}
                                validationErrors={{ minLength: 'Min character length is 4' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                bookmark
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                variant="outlined"
                                disabled={!!editData}
                            />
                            {!editData && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="password"
                                    name="password"
                                    label="Password"
                                    placeholder="Password"
                                    validations={{ minLength: 8, equalsField: 'password_confirmation' }}
                                    validationErrors={{ equalsField: 'Passwords do not match', minLength: 'Password must be at least 8 characters' }}
                                    InputProps={{
                                        autoComplete: 'new-password',
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="text-20 material-icons" color="action">
                                                    vpn_key
                                                </i>
                                            </InputAdornment>
                                        ),
                                        autocomplete: 'new-password',
                                    }}
                                    variant="outlined"
                                    required
                                />
                            )}
                            {!editData && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="password"
                                    name="password_confirmation"
                                    label="Confirm Password"
                                    placeholder="Confirm Password"
                                    validations={{ minLength: 8, equalsField: 'password' }}
                                    validationErrors={{ equalsField: 'Passwords do not match', minLength: 'Password must be at least 8 characters' }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="text-20 material-icons" color="action">
                                                    vpn_key
                                                </i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                    required
                                />
                            )}
                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="email"
                                label="Email"
                                placeholder="Email"
                                validations="isEmail"
                                validationError="This is not a valid email"
                                value={editData ? editData.email : ''}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                alternate_email
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                variant="outlined"
                                disabled={!!editData}
                            />
                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="secondary_email"
                                label="Secondary Email"
                                validations="isEmail"
                                value={editData ? editData.secondary_email : ''}
                                validationError="This is not a valid email"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                alternate_email
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                            />
                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="address1"
                                label="Address 1"
                                placeholder="Address 1"
                                value={editData ? editData.address1 : ''}
                                InputProps={{
                                    autoComplete: 'new-password',
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                home
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                            />
                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="address2"
                                label="Address 2"
                                placeholder="Address 2"
                                value={editData ? editData.address2 : ''}
                                InputProps={{
                                    autoComplete: 'new-password',
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                home
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                            />
                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="town"
                                label="Town"
                                placeholder="Town"
                                value={editData ? editData.town : ''}
                                InputProps={{
                                    autoComplete: 'new-password',
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                place
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                            />
                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="postal_code"
                                label="Postal / ZIP Code"
                                placeholder="Postal / ZIP Code"
                                value={editData ? editData.postal_code : ''}
                                InputProps={{
                                    autoComplete: 'new-password',
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                mail_outline
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                            />
                            {(region === Region.NL || region === Region.PEI || region === Region.RF) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="lab_number"
                                    label="Lab Number"
                                    placeholder="Lab Number"
                                    value={editData && editData.lab_number ? editData.lab_number.toString() : ''}
                                    validations={{ isNumeric: true, isInt: true }}
                                    validationError="This is not a valid lab number"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="text-20 material-icons" color="action">
                                                    local_pharmacy
                                                </i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    required
                                    variant="outlined"
                                />
                            )}
                            {region !== Region.CDI && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="route_region"
                                    label="Region"
                                    placeholder="Route Region"
                                    value={editData ? editData.route_region : ''}
                                    validationError="This is not a valid Region"
                                    InputProps={{
                                        autoComplete: 'new-password',
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="text-20 material-icons" color="action">
                                                    local_pharmacy
                                                </i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}
                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="num_of_tank"
                                label={`Number of ${localization.general.tank}s`}
                                placeholder={`Number of ${localization.general.tank}s`}
                                value={editData ? editData.tank.toString() : numOfTanks}
                                validations={{ isNumeric: true, isInt: true }}
                                onChange={(e) => this.handleTankChange(e.target.value)}
                                validationError={`This is not a valid number of ${localization.general.tank}s`}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                view_week
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                variant="outlined"
                            />

                            {![Region.UDA, Region.CDI, Region.LEGACY].includes(region) &&
                                locations.map((e, i) => (
                                    <div>
                                        <Typography className="font-bold">{`Location ${i + 1}`}</Typography>
                                        <SearchableSelect
                                            value={newTanks
                                                .filter((tank) => tank.tank_location === (i + 1).toString())
                                                .map((tank) => {
                                                    return tank.tank_number;
                                                })}
                                            options={Object.keys(arrayToMap(tanks, 'tank_number'))}
                                            onChange={(event, value) => this.handleChangeTankSelection(event, value, (i + 1).toString())}
                                            label={'Tanks'}
                                            multiple
                                        />
                                    </div>
                                ))}

                            {region === Region.UDA && (
                                <div>
                                    {tanks.map((e, i) => {
                                        // Filter out used tanks
                                        const availableTanks = tankList.filter(({ value }) => e.tank_number === value || tanks.findIndex((t) => t.tank_number === value) < 0);

                                        return (
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '30%' }}>
                                                    <SearchableSelect value={e.tank_number} options={availableTanks} onChange={(event, value) => this.handleTankNumberChange(value, i)} label="Tank Number" closeOnSelect />
                                                </div>
                                                <TextFieldFormsy
                                                    className="my-16 mx-auto w-1/3 ml-12"
                                                    type="text"
                                                    name="tank_location"
                                                    label="Tank Location"
                                                    placeholder="Location"
                                                    value={e.tank_location}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <i className="text-21 material-icons" color="action">
                                                                    view_week
                                                                </i>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    variant="outlined"
                                                    onChange={(value) => this.handleTankLocationChange(value, i)}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            {region === Region.UDA && (
                                <div>
                                    <Button variant="contained" className="my-12 w-1/5" style={{ alignItems: 'right' }} aria-label="ADD TANK" onClick={this.handleAddTank}>
                                        ADD TANK
                                    </Button>
                                    <Button variant="contained" className="my-12 w-1/5 ml-8" style={{ alignItems: 'right' }} aria-label="REMOVE TANK" onClick={this.handleRemoveTank}>
                                        REMOVE TANK
                                    </Button>
                                </div>
                            )}
                            {region === Region.CDI && (
                                <>
                                    <SelectSearchFormsy
                                        className="my-16"
                                        name="hauling_type"
                                        label="Hauling Type"
                                        options={[
                                            { value: '', name: 'None' },
                                            { value: 'number_of_stops', name: 'Number of Stops' },
                                            { value: 'amount_per_stop', name: 'Amount per Stop' },
                                            { value: 'limit_by_days', name: 'Limit by Days' },
                                        ]}
                                        value={hauling_type}
                                        onChange={(value) => this.handleHaulingTypeChange(value)}
                                        variant="outlined"
                                    />
                                    {hauling_type && (
                                        <TextFieldFormsy
                                            className="my-16"
                                            type="text"
                                            name="hauling_type_stop_limit"
                                            placeholder="Hauling Type Limit"
                                            label="Hauling Type Limit"
                                            value={editData ? editData.hauling_type_stop_limit : ''}
                                            validations={{ isNumeric: true, isInt: true }}
                                            validationError="This is not a valid hauling type limit"
                                            required={hauling_type ? hauling_type === 'amount_per_stop' : editData && editData.hauling_type === 'amount_per_stop'}
                                            variant="outlined"
                                        />
                                    )}
                                    <SelectSearchFormsy
                                        className="my-16"
                                        variant="outlined"
                                        name="self_haul"
                                        label="Self Haul"
                                        options={[
                                            { value: false, name: 'No' },
                                            { value: true, name: 'Yes' },
                                        ]}
                                        value={selfHaul}
                                        onChange={this.handleSelfHaul}
                                    />
                                    <SelectSearchFormsy
                                        className="my-16"
                                        variant="outlined"
                                        name="route_region"
                                        label="District"
                                        options={[
                                            { value: '1', name: '1' },
                                            { value: '2', name: '2' },
                                            { value: '3', name: '3' },
                                            { value: '4', name: '4' },
                                        ]}
                                        value={editData ? editData.route_region : district}
                                        onChange={this.handleDistrictChange}
                                    />
                                    <SelectSearchFormsy
                                        className="my-16"
                                        variant="outlined"
                                        label="Zone"
                                        name="zone"
                                        options={zones.map((entry) => ({
                                            value: entry.name,
                                            name: entry.value,
                                        }))}
                                        value={editData ? editData.zone : zone}
                                        onChange={this.handleZoneChange}
                                    />
                                    <SelectSearchFormsy
                                        className="my-16"
                                        variant="outlined"
                                        label="Default Hauler"
                                        name="default_hauling_company"
                                        options={haulingCompanies.map((entry) => ({
                                            value: entry.value,
                                            name: entry.label,
                                        }))}
                                        value={editData ? editData.default_hauling_company : defaultHauler}
                                        onChange={this.handleHaulingChange}
                                    />
                                    <SelectSearchFormsy
                                        className="my-16"
                                        variant="outlined"
                                        name="jurisdiction_name"
                                        label="Jurisdiction Name"
                                        options={[
                                            { value: 'cdfa', name: 'CDFA' },
                                            { value: 'fresno', name: 'Fresno' },
                                            { value: 'sanJoaquin', name: 'San Joaquin' },
                                            { value: 'stanislaus', name: 'Stanislaus' },
                                            { value: 'tulare', name: 'Tulare' },
                                        ]}
                                        value={editData ? editData.jurisdiction_name : jurisdiction_name}
                                        onChange={this.handleJurisdictionChange}
                                    />
                                </>
                            )}

                            {[Region.NL, Region.PEI, Region.RF].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="daily_fluid_milk_quota"
                                    label={localization.general.daily_fluid_milk_quota}
                                    placeholder={localization.general.daily_fluid_milk_quota}
                                    value={editData && editData.daily_fluid_milk_quota ? editData.daily_fluid_milk_quota.toString() : ''}
                                    validations={{ isNumeric: true, isInt: true }}
                                    validationError="This is not a valid number"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="text-20 material-icons" color="action">
                                                    local_drink
                                                </i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    required={region !== Region.CDI}
                                    variant="outlined"
                                />
                            )}
                            {[Region.CDI, Region.UDA].includes(region) && (
                                <SelectSearchFormsy
                                    className="my-16"
                                    name="default_measurement_method"
                                    label="Pickup Type"
                                    variant="outlined"
                                    options={[
                                        { value: 'scale', name: 'Scale' },
                                        { value: 'dip', name: [Region.CDI, Region.LEGACY].includes(region) ? 'Stick' : 'Dip' },
                                        { value: 'estimate', name: 'Estimate' },
                                    ]}
                                    value={this.state.pickupType || ''}
                                />
                            )}
                            {[Region.LEGACY].includes(region) && (
                                <SelectSearchFormsy
                                    className="my-16"
                                    name="default_measurement_method"
                                    label="Pickup Type"
                                    variant="outlined"
                                    options={[
                                        { value: 'scale', name: 'Scale' },
                                        { value: 'dip', name: 'Dip' },
                                        { value: 'estimate', name: 'Estimate' },
                                        { value: 'tube', name: 'Sight Tube' },
                                    ]}
                                    value={this.state.pickupType || ''}
                                />
                            )}
                            {region === Region.NL && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="industrial_MSQ"
                                    label="Market Share Quota"
                                    placeholder="Market Share Quota"
                                    value={editData ? editData.industrial_MSQ.toString() : ''}
                                    validations={{ isNumeric: true, isInt: true }}
                                    validationError="This is not a valid number"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="text-20 material-icons" color="action">
                                                    trending_up
                                                </i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    required
                                    variant="outlined"
                                />
                            )}
                            {![Region.CDI, Region.LEGACY].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="CMTNumber"
                                    label="License Number"
                                    placeholder="License Number"
                                    value={editData ? editData.CMTNumber : ''}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="text-20 material-icons" color="action">
                                                    list_alt
                                                </i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    required
                                    variant="outlined"
                                />
                            )}

                            {region === Region.UDA && (
                                <>
                                    <div className="w-full my-16">
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <DatePicker data-testid={generateTestId('rBST Anniversary Date', 'date-picker')} views={['year', 'month']} openTo="year" label="rBST Anniversary Date" value={rBSTdate} disableToolbar onChange={this.handleDateChange} renderInput={(params) => <TextField {...params} helperText={null} />} fullWidth />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <FormGroup row>
                                        <FormControlLabel control={<Switch checked={isFairlifeProducer} value onChange={this.handleToggleChange('isFairlifeProducer')} />} label="Fairlife Approved Producer?" />
                                    </FormGroup>
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="text"
                                        name="bank_name"
                                        label="Bank Name"
                                        placeholder="Bank Name"
                                        value={editData ? editData.bank_name : ''}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        account_balance
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                </>
                            )}

                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="barcode"
                                label="Barcode Value"
                                placeholder="Barcode Value"
                                value={editData ? editData.barcode : ''}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                list_alt
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                required={['PEI'].includes(region)}
                            />
                            <SelectSearchFormsy className="my-16" name="milk_category" label="Category *" variant="outlined" options={milkCategory} value={this.state.milkCategory || ''} required disabled={editData?.milk_category} />
                            {!!editData && region !== Region.RF && (
                                <div>
                                    <Typography className="font-bold">{`Bulk ${localization.general.tank} Chart`}</Typography>

                                    <Grid container spacing={8}>
                                        <Grid item xs={12} className="w-full">
                                            <FormControl className="w-full">
                                                <SelectSearchFormsy
                                                    className="my-16"
                                                    name="bulk_chart_tank"
                                                    label={localization.general.tank}
                                                    options={Array.from({ length: parseInt(editData.tank) }, (e, i) => i + 1).map((n) => ({ value: n.toString(), name: n.toString() }))}
                                                    value={selectedBulkTank}
                                                    onChange={(value) => this.handleBulkTankChange(value)}
                                                    variant="standard"
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                />
                                            </FormControl>

                                            {(!bulk_charts || bulk_charts[selectedBulkTank - 1] === '') && (
                                                <Button variant="contained" color="secondary" onClick={() => this.downloadTemplateChart({ ...this.props, ...this.state })}>
                                                    Download Template Chart
                                                </Button>
                                            )}
                                            {bulk_charts && bulk_charts[selectedBulkTank - 1] !== '' && (
                                                <div>
                                                    <div className="p-8">
                                                        <Button variant="contained" className="p-10" color="secondary" disabled={!bulk_charts} onClick={() => this.downloadChart({ ...this.props, ...this.state })}>
                                                            Download Current Chart
                                                        </Button>
                                                    </div>
                                                    <div className="p-8">
                                                        <Button variant="contained" className="p-10" color="secondary" disabled={!bulk_charts} onClick={() => this.setState({ deleteDialogOpen: true })}>
                                                            Delete Current Chart
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}
                                            <input style={{ display: 'none' }} id="contained-button-file" type="file" hidden ref={this.fileInput} onChange={() => this.uploadNewChart({ ...this.props, ...this.state })} />
                                            <div className="p-8">
                                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                                <label htmlFor="contained-button-file">
                                                    <Button variant="contained" className="p-10" component="span">
                                                        Upload/Replace Chart
                                                    </Button>
                                                </label>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                            <Box display={'flex'}>
                                <GeofenceManager
                                    center={{ lat: latitude, lng: longitude }}
                                    onChange={this.handleEditCircleFromMap}
                                    maxFences={1}
                                    user_id={editData?.id ?? editData?._id}
                                    // Force Line Indentation
                                />
                            </Box>
                            <Button type="submit" variant="contained" color="primary" className="mx-auto my-16" aria-label={editData ? 'EDIT PRODUCER' : 'ADD PRODUCER'} disabled={!canSubmit}>
                                {editData ? 'Edit Producer' : 'Add Producer'}
                            </Button>
                        </Formsy>
                        <Dialog open={deleteDialogOpen} onClose={() => this.setState({ deleteDialogOpen: false })} fullWidth maxWidth="sm" aria-labelledby="dialog-title">
                            <DialogTitle id="dialog-title">Delete</DialogTitle>
                            <DialogContent>
                                <DialogContentText className="text-black">{`Delete current bulk ${localization.general.tank} chart?`}</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.deleteBulkChart(this.props)} variant="contained" color="secondary">
                                    Delete
                                </Button>
                                <Button onClick={() => this.setState({ deleteDialogOpen: false })} color="primary">
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label */}
                        {fileToLoad && <a href={fileToLoad} id="bulkChart" download />}
                    </div>
                )}
            </LocalizationConsumer>
        );
    }
}

function mapStateToProps({ mainReducer, persisted }) {
    return { region: persisted.auth.user.data.region };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addProducer: Actions.addProducer,
            editUser: Actions.editUser,
            showMessage: FuseActions.showMessage,
            getHaulingListDetails: Actions.getHaulingListDetails,
        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditProducerForm));
