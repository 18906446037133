import { buildFieldFilter } from '../queries';
import { buildExpressQuery } from '../queries/builders';

export const listProcessedFluidRouteSessionsQuery = ({ fields, start, end, removeOrder, populate, hauling_id, withoutPickup, withoutDropoff }) => {
    const endpoint = '/processed-fluid/route-sessions';
    const params = {};

    if (!removeOrder) {
        params.order = 'created_at DESC';
    }
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }

    params.populate = {
        origin_processor_id: ['name'],
        destination_processor_id: ['name'],
        processed_fluid_adjustments_id: ['amount', 'butterfat_percent', 'butterfat_weight', 'status', 'action_required', 'total_solids_percent', 'total_solids_weight'],
        dropoff_id: [],
        pickup_id: [],
        hauling_id: ['full_name', 'name'],
        driver_id: ['full_name', 'name'],
        trailer_id: ['trailer_number'],
    };

    if (start && end) {
        params.where = { ...params.where, start_date: { between: [start, end] } };
    }

    if (hauling_id) {
        params.where = { ...params.where, hauling_id };
    }

    if (withoutPickup) {
        params.where = { ...params.where, pickup_id: { eq: null } };
    }

    if (withoutDropoff) {
        params.where = { ...params.where, dropoff_id: { eq: null } };
    }

    if (populate) {
        params.populate = populate;
    }

    return buildExpressQuery(endpoint, { params });
};

export const editProcessedFluidRouteSession = ({ model, id }) => {
    const endpoint = `/processed-fluid/route-sessions/${id}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const bulkEditProcessedFluidRouteSession = ({ model, keys }) => {
    const endpoint = '/schedule-fluid-item/bulk';
    const body = { ids: keys, ...model };
    return buildExpressQuery(endpoint, { body });
};

export const getProcessedFluidRouteSessionQuery = ({ id, populate, filterDeleted, fields, status, ids, start, end, queryBuilder = buildExpressQuery }) => {
    const endpoint = '/processed-fluid/route-sessions';
    const params = {};
    if (id) {
        params.where = { ...params.where, _id: id };
    }
    if (populate) {
        params.populate = populate;
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (status) {
        params.where = { ...params.where, status };
    }
    if (ids) {
        params.where = { ...params.where, id: { inq: ids } };
    }
    if (start && end) {
        params.where = { ...params.where, created_at: { between: [start, end] } };
    }
    return queryBuilder(endpoint, { params });
};

export const createProcessFluidRouteSession = ({ model }) => {
    const endpoint = '/processed-fluid/route-sessions';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};
