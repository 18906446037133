import { AbilityBuilder, PureAbility } from '@casl/ability';

function UserManagementPermissions(user) {
    const { can, rules } = new AbilityBuilder(PureAbility);
    const {
        role,
        data: { permissions, subuser },
    } = user;

    if (role === 'admin') {
        const permissionMap = [
            { key: 'processor', listPermission: 'ListProcessors', viewPermission: 'AccessPlantManagement' },
            { key: 'producer', listPermission: 'ListProducers', viewPermission: 'AccessProducerManagement' },
            { key: 'transport', listPermission: 'ListHaulingCompanies', viewPermission: 'AccessHaulerManagement' },
        ];

        if (!subuser) {
            permissionMap.forEach(({ key }) => {
                can('mutate', key);
                can('view', key);
            });
        } else {
            permissionMap.forEach(({ key, listPermission, viewPermission }) => {
                if (permissions.includes(listPermission)) {
                    can('mutate', key);
                }
                if (permissions.includes(viewPermission)) {
                    can('view', key);
                }
            });
        }
    }

    return rules;
}

export default UserManagementPermissions;
