import React, { useEffect, useCallback, useState } from 'react';
import { FormProvider, useForm, Controller, useFieldArray } from 'react-hook-form';
import ReactHookFormInput from '../form-components/ReactHookFormInput';
import { Button, Grid, Typography, debounce, InputAdornment, IconButton } from '@material-ui/core';
import { Alert } from '@mui/material';
import { Delete } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactHookFormSearchableSelect from '../form-components/ReactHookFormSearchableSelect';
import SwitchWithLabel from '../form-components/SwitchWithLabel';
import {
    getAssignmentConfigurationFilterTypes,
    memberTypes,
    paymentType,
    getJurisdictions,
    getMilkType,
    getBasis,
    getCategory,
    AssignmentCategories,
    getDumpedMilkRateForAssignmentConfiguration,
    fixedBasis,
    percentBasis,
    dumpedMilkBasis,
    milkAmountBasis,
    grossAndNetPayBasis,
    advanceFrequency,
    assignmentGroups,
    dayOfWeek,
} from '../form-components/AssigneeSelects';
import { state, payZone, regionStateList } from '../form-components/ReactHookProducerFormSelects';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import _ from 'lodash';
import { addAssignmentConfiguration, editAssignmentConfiguration, showMessage } from '../../store/actions';
import { generateTestId, getUserRegion, setSubmitModelSelectValues, getAllFederalOrders } from '../../../utils';
import { AssignmentConfigurationSchema, AssignmentConfigurationEditSchema } from '../form-schemas/AssignmentConfigurationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { fullMonthSelectOptions, pickupRelatedHaulingBasis } from '../form-components/HaulingContractSelectOptions';
import Split from '../../split';
import { REGION } from '../../constants';

const AssignmentConfigurationForm = ({ editData, producers, assignees, haulers, processors }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const region = getUserRegion();
    const stateList = _.isEmpty(regionStateList(region)) ? state : regionStateList(region);
    const jurisdictionsOptions = getJurisdictions(region);
    const payGroupOptions = payZone(region);
    const federalOrderOptions = getAllFederalOrders(true);
    const isEdit = editData != null;
    const [filterError, setFilterError] = useState(null);
    const formType = !_.isEmpty(producers) ? 'producer' : 'hauler';
    const isProducerForm = formType === 'producer';
    const userRole = isProducerForm ? 'producer' : 'transport';
    const filterTypes = getAssignmentConfigurationFilterTypes(isProducerForm);

    const populateAssignmentFilters = (filters) => {
        if (filters == null) {
            return [];
        }
        const filterNames = Object.keys(filters);
        if (filterNames.length === 1 && filterNames[0] === 'role') {
            return [{ filterType: filterTypes[0] }];
        }
        return filterNames
            .filter((filterName) => filterName !== 'role')
            .map((filterName) => {
                return { filterType: filterTypes.find(({ value }) => value === filterName) };
            });
    };

    const freeSelectDumpedMilkBasis = () => {
        return ![REGION.PRAIRIE, REGION.MMPA].includes(region);
    };

    const reactHookFormMethods = useForm({
        mode: 'all',
        defaultValues: {
            assignmentFilters: populateAssignmentFilters(editData?.filter),
            vendor: assignees.find((assignee) => assignee.value === editData?.vendor?.id),
            description: editData?.description,
            filter:
                editData?.filter != null
                    ? {
                          role: userRole,
                          pay_category: memberTypes.find((memberType) => memberType.value === editData.filter?.pay_category),
                          milk_category: memberTypes.find((memberType) => memberType.value === editData.filter?.milk_category),
                          state: stateList.find((stateItem) => stateItem.value === editData.filter?.state),
                          federal_order: federalOrderOptions.find((federalOrder) => federalOrder.value === editData.filter?.federal_order),
                          pay_group: payGroupOptions.find((payGroup) => payGroup.value === editData.filter?.pay_group),
                          jurisdiction_name: jurisdictionsOptions.find((jurisdiction) => jurisdiction.value === editData.filter?.jurisdiction_name),
                          payee: [...producers, ...haulers].filter((payee) => editData.filter?.payee?.find((id) => id === payee.id) != null),
                      }
                    : { role: userRole },
            start_date: editData?.start_date ? moment(editData?.start_date) : null,
            end_date: editData?.end_date ? moment(editData?.end_date) : null,
            exclude_pickups_before_date: fullMonthSelectOptions.find(({ value }) => value === editData?.exclude_pickups_before_date),
            processor_ids: processors.filter((processor) => editData?.processor_ids?.find((id) => id === processor.id) != null),
            updated_from: editData?.updated_from,
            payment_type: paymentType.filter(({ value }) => editData?.payment_type?.includes(value)) ?? [],
            basis: getBasis(region, isProducerForm).find((basis) => basis.value === editData?.basis),
            category: getCategory(region).find((category) => category.value === editData?.category),
            payment_rate: editData?.payment_rate,
            monetary_value: editData?.monetary_value,
            milk_amount: editData?.milk_amount,
            milk_type: getMilkType(region).find((milkType) => milkType.value === editData?.milk_type),
            effective_date: null,
            defer_vendor_advance_payment: editData?.defer_vendor_advance_payment ?? false,
            reference: editData?.reference,
            advance_frequency: advanceFrequency.find((frequency) => frequency.value === editData?.advance_frequency),
            group_assignment: assignmentGroups.find((group) => group.value === editData?.group_assignment),
            day_of_week: dayOfWeek.find((day) => day.value === editData?.day_of_week),
            is_permanent_deduction: editData?.group_assignment === 'permanent_deduction',
            account: editData?.account,
            priority: editData?.priority,
        },
        resolver: yupResolver(isEdit ? AssignmentConfigurationEditSchema : AssignmentConfigurationSchema),
    });

    const { handleSubmit, watch, errors, control, getValues, setValue } = reactHookFormMethods;
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'assignmentFilters',
    });

    const producerWatch = watch('filter.payee');
    const filtersWatch = watch('assignmentFilters');
    const remainingFilters = filterTypes.filter(({ value }) => filtersWatch.find(({ filterType }) => filterType?.value === value) == null);
    const basisWatch = watch('basis');
    const startDateWatch = watch('start_date');
    const endDateWatch = watch('end_date');
    const paymentTypeWatch = watch('payment_type');

    useEffect(() => {
        if (paymentTypeWatch?.find((type) => type?.value === 'advance') == null) {
            setValue('defer_vendor_advance_payment', false);
        }
    }, [paymentTypeWatch]);

    useEffect(() => {
        if (!_.isEmpty(filtersWatch)) {
            setFilterError(null);
        }
    }, [filtersWatch]);

    useEffect(() => {
        if (fixedBasis.has(basisWatch?.value)) {
            setValue('payment_rate', null);
        } else {
            setValue('monetary_value', null);
        }
        if (!pickupRelatedHaulingBasis.has(basisWatch?.value)) {
            setValue('exclude_pickups_before_date', null);
        }
        if (!pickupRelatedHaulingBasis.has(basisWatch?.value) && !fixedBasis.has(basisWatch?.value)) {
            setValue('processor_ids', []);
        }
        if (!milkAmountBasis.has(basisWatch?.value)) {
            setValue('milk_type', null);
        }
        if (grossAndNetPayBasis.has(basisWatch?.value)) {
            setValue(
                'category',
                getCategory(region).find((category) => category.value === AssignmentCategories.Assignment)
            );
        }
        if (dumpedMilkBasis.has(basisWatch?.value) && !freeSelectDumpedMilkBasis()) {
            setValue(
                'category',
                getCategory(region).find((category) => category.value === AssignmentCategories.Premium)
            );
        } else {
            setValue('milk_amount', null);
        }
    }, [basisWatch]);

    useEffect(() => {
        getDumpedMilkRateForAssignmentConfiguration(getValues, setValue, region);
    }, [basisWatch, producerWatch, startDateWatch]);

    const transformProducerLabel = (option) => {
        return [option?.license_number, option?.name].filter((part) => part != null && part !== '').join(' - ');
    };

    const transformHaulerLabel = (option) => {
        return [option?.hauler_number, option?.name].filter((part) => part != null && part !== '').join(' - ');
    };

    const transformAssigneeLabel = (option) => {
        return [option?.assignee_number, option?.name, option?.classification_code].filter((part) => part != null && part !== '').join(' - ');
    };

    const post = (submitModel) => {
        const model = submitModel;
        dispatch(addAssignmentConfiguration(model))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Added Assignment' }));
                if (isProducerForm) {
                    history.replace({ pathname: '/producer-assignments' });
                } else {
                    history.replace({ pathname: '/hauler-assignments' });
                }
            })
            .catch((ex) => {
                dispatch(showMessage({ message: 'Could not add Assignment' }));
            });
    };

    const put = (submitModel) => {
        const { _id } = editData;
        const model = submitModel;
        dispatch(editAssignmentConfiguration(model, _id))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Edited Assignment' }));
                if (isProducerForm) {
                    history.replace({ pathname: '/producer-assignments' });
                } else {
                    history.replace({ pathname: '/hauler-assignments' });
                }
            })
            .catch((ex) => {
                dispatch(showMessage({ message: 'Could not edit Assignment' }));
            });
    };

    const mapSubmitModel = (model) => {
        const submitModel = setSubmitModelSelectValues(model);
        // reset vendor data after being overwritten by setSubmitModelSelectValues
        submitModel.vendor = model.vendor;

        Object.keys(submitModel.filter).forEach((filterName) => {
            const filterNotAddedToForm = submitModel.assignmentFilters.find(({ filterType }) => filterType?.value === filterName) == null;
            if (filterNotAddedToForm) {
                delete submitModel.filter[filterName];
                return;
            }
            submitModel.filter[filterName] = filterName === 'payee' ? submitModel.filter[filterName].map((payee) => payee.id) : submitModel.filter[filterName].value;
        });
        submitModel.filter.role = userRole;

        if (submitModel.processor_ids != null || submitModel.processor_ids.length === 0) {
            submitModel.processor_ids = submitModel.processor_ids.map((processor) => processor.id);
        }

        submitModel.exclude_pickups_before_date = submitModel.exclude_pickups_before_date ?? undefined;
        submitModel.payment_type = submitModel.payment_type.map((type) => type.value);
        delete submitModel.assignmentFilters;

        if (submitModel.is_permanent_deduction === true) {
            submitModel.group_assignment = 'permanent_deduction';
        } else {
            submitModel.group_assignment = undefined;
        }
        delete submitModel.is_permanent_deduction;

        Object.keys(submitModel).forEach((field) => (submitModel[field] == null || submitModel[field] === '') && delete submitModel[field]);
        return submitModel;
    };

    const onSubmit = (model) => {
        const hasAtLeastOneFilter = model.assignmentFilters.some(({ filterType }) => filterType != null);
        if (!hasAtLeastOneFilter) {
            setFilterError(`At least one ${formType} group must be selected`);
            return;
        }
        const submitModel = mapSubmitModel(model);

        if (isEdit) {
            put(submitModel);
        } else {
            post(submitModel);
        }
    };

    const debounceSubmit = useCallback(debounce(onSubmit, 500), []);

    const render = () => {
        return (
            <div>
                <FormProvider {...reactHookFormMethods}>
                    <form noValidate onSubmit={handleSubmit(debounceSubmit, errors)}>
                        <Grid container spacing={3}>
                            <Typography variant="subtitle2">Assignee</Typography>
                            <Grid container spacing={3} style={{ paddingBottom: 20 }}>
                                <Grid item xs={12} sm={4}>
                                    <ReactHookFormSearchableSelect label="Assignee" name="vendor" options={assignees.sort((a, b) => Number(a.assignee_number) - Number(b.assignee_number))} customRender={transformAssigneeLabel} required variant="outlined" hideError />
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <ReactHookFormInput label="Description" name="description" type="text" variant="outlined" />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} style={{ paddingBottom: 20 }}>
                                <Grid item xs={12} md={4}>
                                    <ReactHookFormInput label="Account" name="account" type="text" variant="outlined" />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <ReactHookFormInput label="Reference" name="reference" type="text" variant="outlined" />
                                </Grid>
                            </Grid>

                            <Typography variant={'subtitle2'}>{_.capitalize(formType)} Group *</Typography>
                            <Grid item container spacing={3} style={{ paddingBottom: 20 }}>
                                {fields.map((field, idx) => {
                                    return (
                                        <Grid container spacing={3} key={field.id}>
                                            <Grid item xs={12} sm={4}>
                                                <ReactHookFormSearchableSelect label={`${_.capitalize(formType)} Group`} name={`assignmentFilters.${idx}.filterType`} options={remainingFilters} variant={'outlined'} />
                                            </Grid>
                                            {isProducerForm ? (
                                                <Grid item xs={12} sm={4}>
                                                    {filtersWatch[idx]?.filterType?.value === 'pay_category' && <ReactHookFormSearchableSelect label={'Member Type'} name={'filter.pay_category'} options={memberTypes} variant={'outlined'} />}
                                                    {filtersWatch[idx]?.filterType?.value === 'milk_category' && <ReactHookFormSearchableSelect label={'Member Type'} name={'filter.milk_category'} options={memberTypes} variant={'outlined'} />}
                                                    {filtersWatch[idx]?.filterType?.value === 'state' && <ReactHookFormSearchableSelect label={'State'} name={'filter.state'} options={stateList} variant={'outlined'} />}
                                                    {filtersWatch[idx]?.filterType?.value === 'federal_order' && <ReactHookFormSearchableSelect label={'Federal Order'} name={'filter.federal_order'} options={federalOrderOptions} variant={'outlined'} />}
                                                    {filtersWatch[idx]?.filterType?.value === 'pay_group' && <ReactHookFormSearchableSelect label={'Pay Group'} name={'filter.pay_group'} options={payGroupOptions} variant={'outlined'} />}
                                                    {filtersWatch[idx]?.filterType?.value === 'jurisdiction_name' && <ReactHookFormSearchableSelect label={'Jurisdiction'} name={'filter.jurisdiction_name'} options={jurisdictionsOptions} variant={'outlined'} />}
                                                    {filtersWatch[idx]?.filterType?.value === 'payee' && <ReactHookFormSearchableSelect label={'Producers'} defaultValue={[]} name={'filter.payee'} options={producers} multiple chipLimit={1} customRender={transformProducerLabel} variant={'outlined'} />}
                                                </Grid>
                                            ) : (
                                                <Grid item xs={12} sm={4}>
                                                    {filtersWatch[idx]?.filterType?.value === 'state' && <ReactHookFormSearchableSelect label={'State'} name={'filter.state'} options={stateList} variant={'outlined'} />}
                                                    {filtersWatch[idx]?.filterType?.value === 'payee' && <ReactHookFormSearchableSelect label={'Haulers'} defaultValue={[]} name={'filter.payee'} options={haulers} multiple chipLimit={1} customRender={transformHaulerLabel} variant={'outlined'} />}
                                                </Grid>
                                            )}
                                            <Grid container justifyContent="center" alignItems="center" item xs={12} sm={1}>
                                                <IconButton color="primary" aria-label="Delete" component="span" onClick={() => remove(idx)}>
                                                    <Delete />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                                <Grid item xs={12}>
                                    <Button variant="outlined" color="secondary" onClick={() => append({ filterType: null })} disabled={_.isEmpty(remainingFilters)}>
                                        + Add Group
                                    </Button>
                                    {filterError != null && <div style={{ fontSize: 12, color: '#f44336', marginTop: 10 }}>{filterError}</div>}
                                </Grid>
                            </Grid>

                            <Typography variant={'subtitle2'}>Payments</Typography>
                            <Grid container spacing={3} style={{ paddingBottom: 20 }}>
                                <Grid item xs={12} sm={4}>
                                    <ReactHookFormSearchableSelect label={'Payment Type *'} name={'payment_type'} options={paymentType} multiple variant={'outlined'} hideError />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <ReactHookFormSearchableSelect label={'Basis'} name={'basis'} required options={getBasis(region, isProducerForm)} variant={'outlined'} hideError />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <ReactHookFormSearchableSelect label={'Category'} name={'category'} options={getCategory(region)} required disabled={grossAndNetPayBasis.has(basisWatch?.value) || (dumpedMilkBasis.has(basisWatch?.value) && !freeSelectDumpedMilkBasis())} variant={'outlined'} hideError />
                                </Grid>

                                {basisWatch?.value && !fixedBasis.has(basisWatch?.value) && (
                                    <>
                                        {dumpedMilkBasis.has(basisWatch?.value) || basisWatch?.value === 'lbs_with_cwt_rate' ? (
                                            <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                                                <ReactHookFormInput name="milk_amount" label="Milk Amount (lbs)" type="text" required={dumpedMilkBasis.has(basisWatch?.value) || basisWatch?.value === 'lbs_with_cwt_rate'} variant={'outlined'} />
                                            </Grid>
                                        ) : (
                                            !grossAndNetPayBasis.has(basisWatch?.value) &&
                                            basisWatch?.value !== 'weekly_average_previous_month_permanent_deductions' &&
                                            basisWatch?.value !== 'half_previous_month_permanent_deductions' && (
                                                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                                                    <ReactHookFormSearchableSelect label={'Milk Type'} name={'milk_type'} options={getMilkType(region)} required={milkAmountBasis.has(basisWatch?.value)} disabled={!milkAmountBasis.has(basisWatch?.value)} variant={'outlined'} hideError />
                                                </Grid>
                                            )
                                        )}
                                        <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                                            <ReactHookFormInput name="payment_rate" label={`Payment Rate${percentBasis.has(basisWatch?.value) ? ' (%)' : ''}`} type="text" required={!fixedBasis.has(basisWatch?.value)} fieldOpts={{ disabled: fixedBasis.has(basisWatch?.value) }} variant={'outlined'} />
                                        </Grid>
                                    </>
                                )}
                                {basisWatch?.value && fixedBasis.has(basisWatch?.value) && (
                                    <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                                        <ReactHookFormInput name="monetary_value" label="Monetary Value" type="text" required={fixedBasis.has(basisWatch?.value)} fieldOpts={{ disabled: !fixedBasis.has(basisWatch?.value) }} variant={'outlined'} />
                                    </Grid>
                                )}
                                {paymentTypeWatch?.find((type) => type?.value === 'advance') != null && (
                                    <Grid item xs={12} sm={4}>
                                        <SwitchWithLabel name="defer_vendor_advance_payment" mainLabel="Defer Vendor Advance Payment" />
                                    </Grid>
                                )}
                                {paymentTypeWatch?.find((type) => type?.value === 'advance') != null && Split.getBooleanTreatment('can_use_weekly_pay_frequency') && (
                                    <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                                        <ReactHookFormSearchableSelect name="advance_frequency" label="Advance Frequency" type="text" required variant={'outlined'} options={advanceFrequency} />
                                    </Grid>
                                )}
                                {basisWatch?.value === 'weekly_average_previous_month_permanent_deductions' && Split.getBooleanTreatment('can_use_weekly_pay_frequency') && (
                                    <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                                        <ReactHookFormSearchableSelect name="day_of_week" label="Day of the Week" type="text" required variant={'outlined'} options={dayOfWeek} />
                                    </Grid>
                                )}
                                {Split.getBooleanTreatment('can_use_weekly_pay_frequency') && paymentTypeWatch?.find((type) => type?.value === 'settlement') != null && (
                                    <Grid item xs={12} sm={4}>
                                        <SwitchWithLabel name="is_permanent_deduction" mainLabel="Permanent Deduction" />
                                    </Grid>
                                )}
                                <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
                                    <ReactHookFormInput name="priority" label="Priority" type="number" variant={'outlined'} required />
                                </Grid>
                            </Grid>

                            <Typography variant={'subtitle2'}>Applicable Period</Typography>
                            <Grid container spacing={3} style={{ paddingBottom: 20 }}>
                                <Grid item xs={12} sm={4}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <Controller
                                            name="start_date"
                                            control={control}
                                            defaultValue={null}
                                            render={({ field: { onChange, value } }) => {
                                                return (
                                                    <DatePicker
                                                        data-testid={generateTestId('Start Date', 'date-picker')}
                                                        openTo="year"
                                                        label="From"
                                                        disableToolbar
                                                        views={['year', 'month']}
                                                        format={'MMM DD, YYYY'}
                                                        value={value?.startOf('month') || null}
                                                        onChange={onChange}
                                                        required
                                                        fullWidth
                                                        inputVariant={'outlined'}
                                                        style={{ margin: '8px 0' }}
                                                        disabled={isEdit}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <i className="text-20 material-icons" color="action">
                                                                        date_range
                                                                    </i>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <Controller
                                            name="end_date"
                                            control={control}
                                            defaultValue={null}
                                            render={({ field: { onChange, value } }) => {
                                                return (
                                                    <DatePicker
                                                        data-testid={generateTestId('End Date', 'date-picker')}
                                                        openTo="year"
                                                        label="To"
                                                        disableToolbar
                                                        views={['year', 'month']}
                                                        format={'MMM DD, YYYY'}
                                                        value={value?.endOf('month') || null}
                                                        onChange={onChange}
                                                        fullWidth
                                                        inputVariant={'outlined'}
                                                        style={{ margin: '8px 0' }}
                                                        clearable
                                                        minDate={moment(startDateWatch).isValid() ? moment(startDateWatch).endOf('month') : undefined}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <i className="text-20 material-icons" color="action">
                                                                        date_range
                                                                    </i>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>

                            {(pickupRelatedHaulingBasis.has(basisWatch?.value) || fixedBasis.has(basisWatch?.value)) && (
                                <>
                                    <Typography variant={'subtitle2'} style={{ paddingBottom: 20 }}>
                                        Additional Specifications
                                    </Typography>
                                    <Grid container spacing={3} style={{ paddingBottom: 20 }}>
                                        {!fixedBasis.has(basisWatch?.value) && (
                                            <Grid item xs={12} sm={4}>
                                                <ReactHookFormSearchableSelect label={'Exclude Pickups Before Date'} name={'exclude_pickups_before_date'} options={fullMonthSelectOptions} variant={'outlined'} />
                                            </Grid>
                                        )}
                                        <Grid item xs={12} sm={4}>
                                            <ReactHookFormSearchableSelect label={'Processors'} name={'processor_ids'} options={processors} multiple variant={'outlined'} chipLimit={1} customRender={transformProducerLabel} defaultValue={[]} />
                                        </Grid>
                                    </Grid>
                                </>
                            )}

                            {isEdit && (
                                <>
                                    <Typography variant={'subtitle2'}>Effective Date *</Typography>
                                    <Grid container spacing={3} style={{ paddingBottom: 20 }}>
                                        <Grid item xs={12} sm={4}>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <Controller
                                                    name="effective_date"
                                                    control={control}
                                                    defaultValue={null}
                                                    render={({ field: { onChange, value } }) => {
                                                        return (
                                                            <DatePicker
                                                                data-testid={generateTestId('Effective Date', 'date-picker')}
                                                                openTo="year"
                                                                label="Date"
                                                                disableToolbar
                                                                views={['year', 'month']}
                                                                format={'MMM DD, YYYY'}
                                                                value={value?.startOf('month') || null}
                                                                onChange={onChange}
                                                                required={isEdit}
                                                                fullWidth
                                                                inputVariant={'outlined'}
                                                                style={{ margin: '8px 0' }}
                                                                minDate={moment(startDateWatch).isValid() ? moment(startDateWatch).startOf('month') : undefined}
                                                                maxDate={moment(endDateWatch).isValid() ? moment(endDateWatch).endOf('month') : undefined}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <i className="text-20 material-icons" color="action">
                                                                                date_range
                                                                            </i>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} style={{ paddingBottom: 20 }}>
                                        <Grid item xs={12}>
                                            <Alert severity="info">Editing this assignment will end the current assignment and create a new assignment that will be effective on the date above.</Alert>
                                        </Grid>
                                    </Grid>
                                </>
                            )}

                            <Grid container direction="row" justifyContent="flex-end" spacing={3}>
                                <Grid item>
                                    <Button onClick={() => history.goBack()} variant="contained" color="default">
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button type="submit" variant="contained" color="secondary">
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </div>
        );
    };
    return render();
};

export default AssignmentConfigurationForm;
