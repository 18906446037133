import moment from 'moment-timezone';
import _ from 'lodash';
import Region from 'app/constants/region';
import Split from '../../split';
import { getAssignmentListDetails } from 'app/repository';

export const cdiBasis = [
    { value: 'fixed', name: 'Fixed' },
    { value: 'cwt', name: 'CWT' },
    { value: 'lbs', name: 'Lbs' },
    { value: 'gross_price', name: 'Gross Price at Test' },
];

const legacyBasis = [
    { value: 'fixed', name: 'Fixed' },
    { value: 'cwt', name: 'CWT' },
    { value: 'lbs', name: 'Lbs' },
    { value: 'per_advance_day', name: 'Weighted Per Advance Day' },
];

const defaultBasis = [
    { value: 'fixed', name: 'Fixed' },
    { value: 'cwt', name: 'CWT' },
    { value: 'percent_gross_pay', name: 'Percent of Gross Pay' },
    { value: 'percent_net_pay', name: 'Percent of Net Pay' },
    { value: 'percent_cwt_milk_value', name: 'Percent of CWT Milk Value' },
    { value: 'lbs_with_cwt_rate', name: 'Lbs with CWT Rate' },
];

const uncBasis = () => [
    { value: 'fixed', name: 'Fixed' },
    { value: 'cwt', name: 'CWT' },
    { value: 'percent_gross_pay', name: 'Percent of Gross Pay' },
    { value: 'percent_net_pay', name: 'Percent of Net Pay' },
    { value: 'percent_cwt_milk_value', name: 'Percent of CWT Milk Value' },
    { value: 'lbs_with_cwt_rate', name: 'Lbs with CWT Rate' },
    ...(Split.getBooleanTreatment('can_use_weekly_pay_frequency') ? [{ value: 'weekly_average_previous_month_permanent_deductions', name: 'Weekly Average of Previous Months Permanent Deductions' }] : []),
    ...(Split.getBooleanTreatment('can_use_weekly_pay_frequency') ? [{ value: 'half_previous_month_permanent_deductions', name: 'Half of Previous Months Permanent Deductions' }] : []),
];

const haulerBasis = [
    { value: 'fixed', name: 'Fixed' },
    { value: 'cwt', name: 'CWT' },
];

export const paymentMethod = [
    { name: 'Check', value: 'check' },
    { name: 'EFT', value: 'eft' },
    { name: 'Internal', value: 'internal' },
];

export const deductionType = [
    { name: 'Mandatory', value: 'mandatory' },
    { name: 'Discretionary', value: 'discretionary' },
];

export const status = [
    { name: 'Active', value: 'active' },
    { name: 'Inactive', value: 'inactive' },
];

const legacyMilkTypes = [
    { value: 'all', name: 'All Milk' },
    { value: 'a', name: 'Grade A' },
    { value: 'all_snf', name: 'All Milk SNF' },
    { value: 'gradeA_snf', name: 'Grade A SNF' },
    { value: 'quota_snf', name: 'Quota SNF' },
    { value: 'non_quota_snf', name: 'Non-Quota SNF' },
];

const defaultMilkTypes = [
    { value: 'all', name: 'All Milk' },
    { value: 'a', name: 'Grade A' },
];

const prairieMilkTypes = [
    { value: 'all', name: 'All Milk' },
    { value: 'a', name: 'Grade A' },
    { value: 'all_snf', name: 'All Milk SNF' },
    { value: 'gradeA_snf', name: 'Grade A SNF' },
    { value: 'quota_milk', name: 'Quota Milk' },
    { value: 'non_quota_milk', name: 'Non-Quota Milk' },
];

export const CDIMilkTypes = [
    { value: 'all', name: 'All Milk' },
    { value: 'a', name: 'Grade A' },
    { value: 'all_snf', name: 'All Milk SNF' },
    { value: 'gradeA_snf', name: 'Grade A SNF' },
    { value: 'quota_snf', name: 'Quota SNF' },
    { value: 'non_quota_snf', name: 'Non-Quota SNF' },
];

export const paymentType = [
    { name: 'Settlement', value: 'settlement' },
    { name: 'Advance', value: 'advance' },
];

export const paymentTypeWithBoth = [
    { name: 'Settlement', value: 'settlement' },
    { name: 'Advance', value: 'advance' },
    { name: 'Both', value: 'advance_and_settlement' },
];

export const cdiJurisdictions = [
    { value: 'cdfa', name: 'CDFA' },
    { value: 'fresno', name: 'Fresno' },
    { value: 'sanJoaquin', name: 'San Joaquin' },
    { value: 'stanislaus', name: 'Stanislaus' },
    { value: 'tulare', name: 'Tulare' },
];

export const legacyJurisdictions = [
    { value: 'merced', name: 'Merced' },
    { value: 'madera', name: 'Madera' },
    { value: 'sanJoaquin', name: 'San Joaquin' },
    { value: 'stanislaus', name: 'Stanislaus' },
];

export const AssignmentCategories = {
    Assignment: 'assignment',
    Deduction: 'deduction',
    Premium: 'premium',
    Supplies: 'product',
    Advertising: 'advertising',
    Production: 'production',
};

export const defaultCategories = [
    { value: 'assignment', name: 'Assignment' },
    { value: 'deduction', name: 'Deduction' },
    { value: 'premium', name: 'Premium' },
    { value: 'product', name: 'Supplies' },
    { value: 'advertising', name: 'Advertising' },
    { value: 'production', name: 'Production' },
];

const legacyCategories = [
    { value: 'assignment', name: 'Assignment' },
    { value: 'deduction', name: 'Assessment' },
    { value: 'premium', name: 'Premium' },
    { value: 'quota', name: 'Quota' },
];

export const producerAssignmentConfigurationFilterTypes = [
    { name: 'All Producers', value: 'role' },
    { name: 'Member Type', value: 'milk_category' },
    { name: 'State', value: 'state' },
    { name: 'Federal Order', value: 'federal_order' },
    { name: 'Pay Group', value: 'pay_group' },
    { name: 'Individual Producers', value: 'payee' },
];

export const haulerAssignmentConfigurationFilterTypes = [
    { name: 'All Haulers', value: 'role' },
    { name: 'State', value: 'state' },
    { name: 'Individual Haulers', value: 'payee' },
];

export const getAssignmentConfigurationFilterTypes = (isProducerForm) => {
    return isProducerForm ? producerAssignmentConfigurationFilterTypes : haulerAssignmentConfigurationFilterTypes;
};

export const memberTypes = [
    { name: 'Member Producer', value: 'member_producer' },
    { name: 'Outside Milk', value: 'outside_milk' },
];

export const advanceFrequency = [
    { name: 'Semi-Monthly', value: 'SM' },
    { name: 'Weekly', value: 'WK' },
];

export const dayOfWeek = [
    { name: 'Monday', value: 'monday' },
    { name: 'Tuesday', value: 'tuesday' },
    { name: 'Wednesday', value: 'wednesday' },
    { name: 'Thursday', value: 'thursday' },
    { name: 'Friday', value: 'friday' },
    { name: 'Saturday', value: 'saturday' },
    { name: 'Sunday', value: 'sunday' },
];

export const assignmentGroups = [{ name: 'Permanent Deduction', value: 'permanent_deduction' }];

export const getJurisdictions = (region) => {
    switch (region) {
        case Region.CDI:
            return cdiJurisdictions;
        case Region.LEGACY:
            return legacyJurisdictions;
        default:
            return [];
    }
};

export const getBasis = (region, isProducerForm = true) => {
    if (!isProducerForm) {
        return haulerBasis;
    }
    switch (region) {
        case Region.LEGACY:
            return legacyBasis;
        case Region.UNC:
            return uncBasis();
        default:
            return defaultBasis;
    }
};

export const getMilkType = (region) => {
    switch (region) {
        case Region.LEGACY:
            return legacyMilkTypes;
        case Region.PRAIRIE:
            return prairieMilkTypes;
        default:
            return defaultMilkTypes;
    }
};

export const getCategory = (region) => {
    switch (region) {
        case Region.LEGACY:
            return legacyCategories;
        default:
            return defaultCategories;
    }
};

export const fixedBasis = new Set(['fixed']);
export const percentBasis = new Set(['percent_net_pay', 'percent_gross_pay', 'percent_cwt_milk_value']);
export const dumpedMilkBasis = new Set(['percent_cwt_milk_value']);
export const milkAmountBasis = new Set(['cwt', 'lbs']);
export const grossAndNetPayBasis = new Set(['percent_net_pay', 'percent_gross_pay']);

const dumpedMilkRatesByRegion = {
    [Region.PRAIRIE]: [
        { assignmentCount: 2, rate: 0 },
        { assignmentCount: 1, rate: 50 },
        { assignmentCount: 0, rate: 75 },
    ],
    [Region.MMPA]: [
        { assignmentCount: 2, rate: 0 },
        { assignmentCount: 0, rate: 75 },
    ],
    [Region.BONGARDS]: [
        { assignmentCount: 1, rate: 0 },
        { assignmentCount: 0, rate: 50 },
    ],
};

const getDumpedMilkFilters = (getValues, editData) => {
    const selectedDate = getValues('date') ?? moment();
    const rolling12Months = 12;
    const dateRange = Array.from({ length: rolling12Months }, (_value, index) => moment(selectedDate).subtract(index, 'months').format('YYYY-MM'));
    const producerId = editData != null ? getValues('producer')?.value : getValues('producers')?.[0]?.value;
    const filters = {
        producer: producerId,
        type_of_charge: getValues('type_of_charge')?.value,
    };
    return {
        filters,
        dateRange,
    };
};

const setDumpedMilkRate = (setValue, dumpedMilkAssignments, dateRange, region, editData) => {
    const filterDumpedMilkAssignments = (assignment) => {
        return assignment.payment_rate < 100 && dateRange.includes(assignment.date) && (editData == null || editData?._id !== assignment._id);
    };
    if (dumpedMilkAssignments?.assignments != null) {
        const filteredAssignments = dumpedMilkAssignments.assignments.filter(filterDumpedMilkAssignments);
        const dumpedMilkRate = dumpedMilkRatesByRegion?.[region]?.find(({ assignmentCount }) => filteredAssignments.length >= assignmentCount);
        if (dumpedMilkRate != null) {
            setValue('payment_rate', dumpedMilkRate.rate);
        }
    }
};

export const getDumpedMilkRate = async (getValues, setValue, editData, region) => {
    const { filters, dateRange } = getDumpedMilkFilters(getValues, editData);
    if (filters.producer != null && dumpedMilkBasis.has(filters.type_of_charge)) {
        const dumpedMilkAssignments = await getAssignmentListDetails(undefined, undefined, filters);
        setDumpedMilkRate(setValue, dumpedMilkAssignments, dateRange, region, editData);
    }
};

const getDumpedMilkFiltersForAssignmentConfiguration = (getValues) => {
    const selectedDate = getValues('start_date') ?? moment();
    const rolling12Months = 12;
    const dateRange = Array.from({ length: rolling12Months }, (_value, index) => moment(selectedDate).subtract(index, 'months').format('YYYY-MM'));
    const producerId = getValues('filter.payee')?.[0]?.id ?? undefined;
    const filters = {
        producer: producerId,
        type_of_charge: getValues('basis')?.value,
    };
    return {
        filters,
        dateRange,
    };
};

const setDumpedMilkRateForAssignmentConfiguration = (setValue, dumpedMilkAssignments, dateRange, region) => {
    const filterDumpedMilkAssignments = (assignment) => {
        return assignment.payment_rate < 100 && dateRange.includes(assignment.date);
    };
    const filteredAssignments = dumpedMilkAssignments.assignments.filter(filterDumpedMilkAssignments);
    const dumpedMilkRate = dumpedMilkRatesByRegion[region]?.find(({ assignmentCount }) => filteredAssignments.length >= assignmentCount);
    if (dumpedMilkRate != null) {
        setValue('payment_rate', dumpedMilkRate.rate);
    }
};

export const getDumpedMilkRateForAssignmentConfiguration = async (getValues, setValue, region) => {
    const { filters, dateRange } = getDumpedMilkFiltersForAssignmentConfiguration(getValues);
    if (filters.producer == null || !dumpedMilkBasis.has(filters.type_of_charge)) {
        return;
    }
    const dumpedMilkAssignments = await getAssignmentListDetails(undefined, undefined, filters);
    setDumpedMilkRateForAssignmentConfiguration(setValue, dumpedMilkAssignments, dateRange, region);
};
