import axios from 'axios';
import Split from '../split';
import { resolveId } from '../../utils';
import { editRouteSessionQuery } from './queries';
import { buildExpressQuery } from './queries/builders';

const editRouteSession = (model, key, forceLoopback = false) =>
    new Promise((resolve, reject) => {
        const useExpressEndpoint = Split.getBooleanTreatment('use_express_route_session_endpoint') && !forceLoopback;

        const query = editRouteSessionQuery({ key, model, ...(useExpressEndpoint && { queryBuilder: buildExpressQuery }) });
        const requestMethod = useExpressEndpoint ? axios.put : axios.patch;

        const request = requestMethod(query.url, { ...query.body });

        request
            .then((response) => {
                resolve({ data: resolveId(response.data) });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to edit route session'));
            });
    });

export default editRouteSession;
