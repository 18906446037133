const ROUTE_SESSION_STATUS = {
    ACTIVE: 'active',
    CLOSED: 'closed',
    INACTIVE: 'inactive',
    PREPARING_LOAD: 'preparing_load',
    AWAITING_PICKUP: 'awaiting_pickup',
    IN_TRANSIT: 'in_transit',
    AWAITING_DROPOFF: 'awaiting_dropoff',
    MANUAL_ADJUSTMENT: 'manual_adjustment',
    CANCELLED: 'cancelled',
    AWAITING_OFFLOAD: 'awaiting_offload',
    // Prettier No Prettying
};

export default ROUTE_SESSION_STATUS;
