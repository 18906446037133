import axios from 'axios';
import { createLoanPaymentQuery } from '../queries';
import parseError from '../errors/utils';

const createLoanPayment = async (model) => {
    try {
        const query = createLoanPaymentQuery({ model });
        const request = await axios.post(query.url, { ...query.body });
        return request.data;
    } catch (error) {
        return { error: parseError({ error }) };
    }
};

export default createLoanPayment;
