import listOfStates from 'us-state-converter';
import Region from '../../constants/region';
import Split from '../../split';
import { getSubmarketsForRegion } from '../../../utils';

const states = listOfStates.only50();

const transformStates = (details) => details.map((value) => ({ name: value.name, value: value.usps }));

export const state = transformStates(states);

export const districts = (region) => {
    switch (region) {
        case Region.CDI:
            return [
                { name: '1', value: '1' },
                { name: '2', value: '2' },
                { name: '3', value: '3' },
                { name: '4', value: '4' },
            ];
        case Region.PRAIRIE:
            return [
                { name: 'District 1- Northern IL', value: '1' },
                { name: 'District 2 - Northeast IN, MI, OH', value: '2' },
                { name: 'District 3 - Southern IA, Northern MO', value: '3' },
                { name: 'District 4 - Southwest/ West IN, KY', value: '4' },
                { name: 'District 5 - Central/ Southeast IL', value: '5' },
                { name: 'District 6 - Southwest IL, Southeast MO', value: '6' },
                { name: 'District 7 - Northern IA', value: '7' },
                { name: 'District 8 - MN, WI', value: '8' },
            ];
        case Region.MMPA:
            return [
                { name: '1', value: '1' },
                { name: '2', value: '2' },
                { name: '4', value: '4' },
                { name: '5', value: '5' },
                { name: '6', value: '6' },
                { name: '8', value: '8' },
                { name: '13', value: '13' },
                { name: '15', value: '15' },
                { name: '23', value: '23' },
                { name: '24', value: '24' },
                { name: '25', value: '25' },
                { name: '27', value: '27' },
                { name: '30', value: '30' },
                { name: '50', value: '50' },
                { name: '60', value: '60' },
                { name: '68', value: '68' },
            ];
        case Region.UNC:
            return getSubmarketsForRegion(region).map((submarket, index) => ({
                name: submarket,
                value: submarket,
            }));
        default:
            return [];
    }
};

export const labRegions = (region) => {
    switch (region) {
        case Region.PRAIRIE:
            return [
                { name: 'IA1', value: 'IA1' },
                { name: 'IA2', value: 'IA2' },
                { name: 'IA3', value: 'IA3' },
                { name: 'IA4', value: 'IA4' },
                { name: 'IA5', value: 'IA5' },
                { name: 'IA6', value: 'IA6' },
                { name: 'IA7', value: 'IA7' },
                { name: 'IA8', value: 'IA8' },
                { name: 'IL1', value: 'IL1' },
                { name: 'IL2', value: 'IL2' },
                { name: 'IL3', value: 'IL3' },
                { name: 'IL4', value: 'IL4' },
                { name: 'IL5', value: 'IL5' },
                { name: 'IL6', value: 'IL6' },
                { name: 'IL7', value: 'IL7' },
                { name: 'IN1', value: 'IN1' },
                { name: 'IN2', value: 'IN2' },
                { name: 'KY', value: 'KY' },
                { name: 'MI', value: 'MI' },
                { name: 'MN', value: 'MN' },
                { name: 'MO', value: 'MO' },
                { name: 'OHIO', value: 'OHIO' },
                { name: 'TX', value: 'TX' },
                { name: 'WI', value: 'WI' },
            ];
        default:
            return [];
    }
};

export const dairyType = [
    { name: 'Milk', value: 'milk' },
    { name: 'Cream', value: 'cream' },
];

export const jurisdictionName = [
    { name: 'Fresno', value: 'fresno' },
    { name: 'CDFA', value: 'cdfa' },
    { name: 'San Joaquin', value: 'sanJoaquin' },
    { name: 'Stanislaus', value: 'stanislaus' },
    { name: 'Tulare', value: 'tulare' },
];

// pass region here
export const measurementMethod = {
    scale: 'Scale',
    dip: 'Stick',
    estimate: 'Estimate',
    tube: 'Sight Tube',
};

export const paymentMethod = [
    { name: 'Check', value: 'check' },
    { name: 'EFT', value: 'eft' },
];

export const prairiePaymentMethod = [
    { name: 'DD', value: 'eft' },
    { name: 'CK', value: 'check' },
];

export const payFrequency = () => [{ name: 'MO', value: 'MO' }, { name: 'SM', value: 'SM' }, ...(Split.getBooleanTreatment('can_use_weekly_pay_frequency') ? [{ name: 'WK', value: 'WK' }] : [])];

export const booleanYesOrNo = [
    { name: 'Yes', value: true },
    { name: 'No', value: false },
];

export const payZone = (region) => {
    switch (region) {
        case Region.CDI:
            return [
                {
                    value: 'aSoCalSnf',
                    name: 'Pay Zone A SO CAL, SNF Quota RQA -0',
                },
                {
                    value: 'aSoCal',
                    name: 'Pay Zone A SO CAL',
                },
                {
                    value: 'bKernSnf',
                    name: 'Pay Zone B Kern-Pay Agreement, SNF Quota RQA 0.20',
                },
                {
                    value: 'cTulareSnfA',
                    name: 'Pay Zone C Tulare, SNF Quota RQA -0.2-Pay Agreement',
                },
                {
                    value: 'cTulareSnfB',
                    name: 'Pay Zone C Tulare, SNF Quota RQA -0.11',
                },
                {
                    value: 'dSoCalSnf',
                    name: 'Pay Zone D NO CAL, SNF Quota RQA -0.11',
                },
            ];
        case Region.PRAIRIE:
            return [
                {
                    value: '7',
                    name: '7',
                },
                {
                    value: '10',
                    name: '10',
                },
                {
                    value: '15',
                    name: '15',
                },
                {
                    value: '20',
                    name: '20',
                },
                {
                    value: '25',
                    name: '25',
                },
                {
                    value: '27',
                    name: '27',
                },
                {
                    value: '30',
                    name: '30',
                },
                {
                    value: '32',
                    name: '32',
                },
                {
                    value: '33',
                    name: '33',
                },
                {
                    value: '35',
                    name: '35',
                },
                {
                    value: '40',
                    name: '40',
                },
                {
                    value: '71',
                    name: '71',
                },
                {
                    value: '90',
                    name: '90',
                },
                {
                    value: '99',
                    name: '99',
                },
            ];
        case Region.MMPA:
            return [
                {
                    value: 'Z1',
                    name: 'Z1',
                },
                {
                    value: 'Z2',
                    name: 'Z2',
                },
                {
                    value: 'Z3',
                    name: 'Z3',
                },
                {
                    value: 'Z4',
                    name: 'Z4',
                },
                {
                    value: 'Z5',
                    name: 'Z5',
                },
                {
                    value: 'Z6',
                    name: 'Z6',
                },
                {
                    value: 'Z7',
                    name: 'Z7',
                },
                {
                    value: 'Z8',
                    name: 'Z8',
                },
                {
                    value: 'Z9',
                    name: 'Z9',
                },
                {
                    value: 'ZA',
                    name: 'ZA',
                },
                {
                    value: 'ZN',
                    name: 'ZN',
                },
                {
                    value: 'ZS',
                    name: 'ZS',
                },
            ];
        case Region.TCJ.NEBRASKA:
            return [{ name: 'Nebraska', value: 'nebraska' }];
        case Region.TCJ.ERIE:
            return [{ name: 'Pay Zone $ 1.8', value: '1_8' }];
        case Region.TCJ.WHITEEAGLE:
            return [
                { name: 'Pay Zone $ 1.8', value: '1_8' },
                { name: 'Pay Zone $ 2.0', value: '2_0' },
                { name: 'Pay Zone $ 5.0', value: '5_0' },
            ];
        case Region.TCJ.GPDA:
            return [
                { name: 'Pay Zone $ 1.7', value: '1_7' },
                { name: 'Pay Zone $ 1.8', value: '1_8' },
            ];
        case Region.TCJ.LIBERTY:
            return [{ name: 'Pay Zone $ 2.5', value: '2_5' }];
        case Region.TCJ.KYTN:
            return [{ name: 'Pay Zone $ 2.7', value: '2_7' }];
        case Region.UNC:
            return [
                { name: '1', value: '1' },
                { name: '2', value: '2' },
                { name: '3', value: '3' },
                { name: '4', value: '4' },
                { name: '5', value: '5' },
                { name: 'Organic', value: 'organic' },
            ];
        case Region.CACIQUE:
            return [{ name: 'Cacique', value: 'cacique' }];
        case 'DEMO':
            return [
                {
                    value: '7',
                    name: '7',
                },
                {
                    value: '10',
                    name: '10',
                },
                {
                    value: '15',
                    name: '15',
                },
                {
                    value: '20',
                    name: '20',
                },
                {
                    value: '25',
                    name: '25',
                },
                {
                    value: '27',
                    name: '27',
                },
                {
                    value: '30',
                    name: '30',
                },
                {
                    value: '32',
                    name: '32',
                },
                {
                    value: '33',
                    name: '33',
                },
                {
                    value: '35',
                    name: '35',
                },
                {
                    value: '40',
                    name: '40',
                },
                {
                    value: '71',
                    name: '71',
                },
                {
                    value: '90',
                    name: '90',
                },
                {
                    value: '99',
                    name: '99',
                },
            ];
        default:
            return [];
    }
};

export const countyData = (region) => {
    switch (region) {
        case Region.CDI:
            return [
                { name: 'San Bernardino', value: '06-071' },
                { name: 'Riverside', value: '06-065' },
                { name: 'San Diego', value: '06-073' },
                { name: 'Los Angeles', value: '06-037' },
                { name: 'Kern', value: '06-029' },
                { name: 'Tulare', value: '06-107' },
                { name: 'Fresno', value: '06-019' },
                { name: 'Kings', value: '06-031' },
                { name: 'Madera', value: '06-039' },
                { name: 'Merced', value: '06-047' },
                { name: 'San Joaquin', value: '06-077' },
                { name: 'Stanislaus', value: '06-099' },
                { name: 'Sacramento', value: '06-067' },
            ];
        case Region.PRAIRIE:
            return [
                { name: '17001 - ADAMS', value: '17001 - ADAMS' },
                { name: '17005 - BOND', value: '17005 - BOND' },
                { name: '17007 - BOONE', value: '17007 - BOONE' },
                { name: '17009 - BROWN', value: '17009 - BROWN' },
                { name: '17011 - BUREAU', value: '17011 - BUREAU' },
                { name: '17015 - CARROLL', value: '17015 - CARROLL' },
                { name: '17019 - CHAMPAIGN', value: '17019 - CHAMPAIGN' },
                { name: '17021 - CHRISTIAN', value: '17021 - CHRISTIAN' },
                { name: '17023 - CLARK', value: '17023 - CLARK' },
                { name: '17027 - CLINTON', value: '17027 - CLINTON' },
                { name: '17033 - CRAWFORD', value: '17033 - CRAWFORD' },
                { name: '17037 - DEKALB', value: '17037 - DEKALB' },
                { name: '17041 - DOUGLAS', value: '17041 - DOUGLAS' },
                { name: '17049 - EFFINGHAM', value: '17049 - EFFINGHAM' },
                { name: '17051 - FAYETTE', value: '17051 - FAYETTE' },
                { name: '17055 - FRANKLIN', value: '17055 - FRANKLIN' },
                { name: '17057 - FULTON', value: '17057 - FULTON' },
                { name: '17067 - HANCOCK', value: '17067 - HANCOCK' },
                { name: '17075 - IROQUOIS', value: '17075 - IROQUOIS' },
                { name: '17077 - JACKSON', value: '17077 - JACKSON' },
                { name: '17079 - JASPER', value: '17079 - JASPER' },
                { name: '17081 - JEFFERSON', value: '17081 - JEFFERSON' },
                { name: '17085 - JODAVIESS', value: '17085 - JODAVIESS' },
                { name: '17089 - KANE', value: '17089 - KANE' },
                { name: '17093 - KENDALL', value: '17093 - KENDALL' },
                { name: '17099 - LASALLE', value: '17099 - LASALLE' },
                { name: '17105 - LIVINGSTON', value: '17105 - LIVINGSTON' },
                { name: '17107 - LOGAN', value: '17107 - LOGAN' },
                { name: '17111 - MCHENRY', value: '17111 - MCHENRY' },
                { name: '17113 - MCLEAN', value: '17113 - MCLEAN' },
                { name: '17117 - MACOUPIN', value: '17117 - MACOUPIN' },
                { name: '17119 - MADISON', value: '17119 - MADISON' },
                { name: '17121 - MARION', value: '17121 - MARION' },
                { name: '17123 - MARSHALL', value: '17123 - MARSHALL' },
                { name: '17133 - MONROE', value: '17133 - MONROE' },
                { name: '17135 - MONTGOMERY', value: '17135 - MONTGOMERY' },
                { name: '17137 - MORGAN', value: '17137 - MORGAN' },
                { name: '17139 - MOULTRIE', value: '17139 - MOULTRIE' },
                { name: '17141 - OGLE', value: '17141 - OGLE' },
                { name: '17143 - PEORIA', value: '17143 - PEORIA' },
                { name: '17145 - PERRY', value: '17145 - PERRY' },
                { name: '17153 - PULASKI', value: '17153 - PULASKI' },
                { name: '17157 - RANDOLPH', value: '17157 - RANDOLPH' },
                { name: '17159 - RICHLAND', value: '17159 - RICHLAND' },
                { name: '17161 - ROCKISLAND', value: '17161 - ROCKISLAND' },
                { name: '17163 - ST.CLAIR', value: '17163 - ST.CLAIR' },
                { name: '17167 - SANGAMON', value: '17167 - SANGAMON' },
                { name: '17173 - SHELBY', value: '17173 - SHELBY' },
                { name: '17177 - STEPHENSON', value: '17177 - STEPHENSON' },
                { name: '17179 - TAZEWELL', value: '17179 - TAZEWELL' },
                { name: '17181 - UNION', value: '17181 - UNION' },
                { name: '17187 - WARREN', value: '17187 - WARREN' },
                { name: '17189 - WASHINGTON', value: '17189 - WASHINGTON' },
                { name: '17191 - WAYNE', value: '17191 - WAYNE' },
                { name: '17195 - WHITESIDE', value: '17195 - WHITESIDE' },
                { name: '17201 - WINNEBAGO', value: '17201 - WINNEBAGO' },
                { name: '18001 - ADAMS', value: '18001 - ADAMS' },
                { name: '18003 - ALLEN', value: '18003 - ALLEN' },
                { name: '18007 - BENTON', value: '18007 - BENTON' },
                { name: '18011 - BOONE', value: '18011 - BOONE' },
                { name: '18021 - CLAY', value: '18021 - CLAY' },
                { name: '18027 - DAVIESS', value: '18027 - DAVIESS' },
                { name: '18033 - DEKALB', value: '18033 - DEKALB' },
                { name: '18037 - DUBOIS', value: '18037 - DUBOIS' },
                { name: '18039 - ELKHART', value: '18039 - ELKHART' },
                { name: '18045 - FOUNTAIN', value: '18045 - FOUNTAIN' },
                { name: '18055 - GREENE', value: '18055 - GREENE' },
                { name: '18069 - HUNTINGTON', value: '18069 - HUNTINGTON' },
                { name: '18083 - KNOX', value: '18083 - KNOX' },
                { name: '18085 - KOSCIUSKO', value: '18085 - KOSCIUSKO' },
                { name: '18091 - LAPORTE', value: '18091 - LAPORTE' },
                { name: '18101 - MARTIN', value: '18101 - MARTIN' },
                { name: '18107 - MONTGOMERY', value: '18107 - MONTGOMERY' },
                { name: '18113 - NOBLE', value: '18113 - NOBLE' },
                { name: '18121 - PARKE', value: '18121 - PARKE' },
                { name: '18123 - PERRY', value: '18123 - PERRY' },
                { name: '18141 - ST.JOSEPH', value: '18141 - ST.JOSEPH' },
                { name: '18147 - SPENCER', value: '18147 - SPENCER' },
                { name: '18151 - STEUBEN', value: '18151 - STEUBEN' },
                { name: '18169 - WABASH', value: '18169 - WABASH' },
                { name: '18179 - WELLS', value: '18179 - WELLS' },
                { name: '18183 - WHITLEY', value: '18183 - WHITLEY' },
                { name: '19005 - ALLAMAKEE', value: '19005 - ALLAMAKEE' },
                { name: '19017 - BREMER', value: '19017 - BREMER' },
                { name: '19019 - BUCHANAN', value: '19019 - BUCHANAN' },
                { name: '19023 - BUTLER', value: '19023 - BUTLER' },
                { name: '19031 - CEDAR', value: '19031 - CEDAR' },
                { name: '19037 - CHICKASAW', value: '19037 - CHICKASAW' },
                { name: '19043 - CLAYTON', value: '19043 - CLAYTON' },
                { name: '19045 - CLINTON', value: '19045 - CLINTON' },
                { name: '19051 - DAVIS', value: '19051 - DAVIS' },
                { name: '19055 - DELAWARE', value: '19055 - DELAWARE' },
                { name: '19061 - DUBUQUE', value: '19061 - DUBUQUE' },
                { name: '19065 - FAYETTE', value: '19065 - FAYETTE' },
                { name: '19067 - FLOYD', value: '19067 - FLOYD' },
                { name: '19069 - FRANKLIN', value: '19069 - FRANKLIN' },
                { name: '19083 - HARDIN', value: '19083 - HARDIN' },
                { name: '19087 - HENRY', value: '19087 - HENRY' },
                { name: '19097 - JACKSON', value: '19097 - JACKSON' },
                { name: '19099 - JASPER', value: '19099 - JASPER' },
                { name: '19111 - LEE', value: '19111 - LEE' },
                { name: '19115 - LOUISA', value: '19115 - LOUISA' },
                { name: '19123 - MAHASKA', value: '19123 - MAHASKA' },
                { name: '19125 - MARION', value: '19125 - MARION' },
                { name: '19131 - MITCHELL', value: '19131 - MITCHELL' },
                { name: '19139 - MUSCATINE', value: '19139 - MUSCATINE' },
                { name: '19151 - POCAHONTAS', value: '19151 - POCAHONTAS' },
                { name: '19157 - POWESHIEK', value: '19157 - POWESHIEK' },
                { name: '19163 - SCOTT', value: '19163 - SCOTT' },
                { name: '19169 - STORY', value: '19169 - STORY' },
                { name: '19171 - TAMA', value: '19171 - TAMA' },
                { name: '19177 - VANBUREN', value: '19177 - VANBUREN' },
                { name: '19183 - WASHINGTON', value: '19183 - WASHINGTON' },
                { name: '19189 - WINNEBAGO', value: '19189 - WINNEBAGO' },
                { name: '19191 - WINNESHIEK', value: '19191 - WINNESHIEK' },
                { name: '19195 - WORTH', value: '19195 - WORTH' },
                { name: '21001 - ADAIR', value: '21001 - ADAIR' },
                { name: '21045 - CASEY', value: '21045 - CASEY' },
                { name: '21079 - GARRARD', value: '21079 - GARRARD' },
                { name: '21087 - GREEN', value: '21087 - GREEN' },
                { name: '21137 - LINCOLN', value: '21137 - LINCOLN' },
                { name: '21199 - PULASKI', value: '21199 - PULASKI' },
                { name: '21203 - ROCKCASTLE', value: '21203 - ROCKCASTLE' },
                { name: '21207 - RUSSELL', value: '21207 - RUSSELL' },
                { name: '21217 - TAYLOR', value: '21217 - TAYLOR' },
                { name: '21231 - WAYNE', value: '21231 - WAYNE' },
                { name: '26015 - BARRY', value: '26015 - BARRY' },
                { name: '26065 - INGHAM', value: '26065 - INGHAM' },
                { name: '26077 - KALAMAZOO', value: '26077 - KALAMAZOO' },
                { name: '26125 - OAKLAND', value: '26125 - OAKLAND' },
                { name: '27015 - BROWN', value: '27015 - BROWN' },
                { name: '27045 - FILLMORE', value: '27045 - FILLMORE' },
                { name: '27055 - HOUSTON', value: '27055 - HOUSTON' },
                { name: '27163 - WASHINGTON', value: '27163 - WASHINGTON' },
                { name: '27169 - WINONA', value: '27169 - WINONA' },
                { name: '29031 - CAPEGIRARDEAU', value: '29031 - CAPEGIRARDEAU' },
                { name: '29071 - FRANKLIN', value: '29071 - FRANKLIN' },
                { name: '29099 - JEFFERSON', value: '29099 - JEFFERSON' },
                { name: '29103 - KNOX', value: '29103 - KNOX' },
                { name: '29137 - MONROE', value: '29137 - MONROE' },
                { name: '29157 - PERRY', value: '29157 - PERRY' },
                { name: '29165 - PLATTE', value: '29165 - PLATTE' },
                { name: '29189 - ST.LOUIS', value: '29189 - ST.LOUIS' },
                { name: '29197 - SCHUYLER', value: '29197 - SCHUYLER' },
                { name: '29199 - SCOTLAND', value: '29199 - SCOTLAND' },
                { name: '35015 - EDDY', value: '35015 - EDDY' },
                { name: '39107 - MERCER', value: '39107 - MERCER' },
                { name: '39125 - PAULDING', value: '39125 - PAULDING' },
                { name: '39171 - WILLIAMS', value: '39171 - WILLIAMS' },
                { name: '47031 - COFFEE', value: '47031 - COFFEE' },
                { name: '47055 - GILES', value: '47055 - GILES' },
                { name: '47099 - LAWRENCE', value: '47099 - LAWRENCE' },
                { name: '47103 - LINCOLN', value: '47103 - LINCOLN' },
                { name: '47117 - MARSHALL', value: '47117 - MARSHALL' },
                { name: '47119 - MAURY', value: '47119 - MAURY' },
                { name: '47177 - WARREN', value: '47177 - WARREN' },
                { name: '47187 - WILLIAMSON', value: '47187 - WILLIAMSON' },
                { name: '48009 - ARCHER', value: '48009 - ARCHER' },
                { name: '48223 - HOPKINS', value: '48223 - HOPKINS' },
                { name: '55021 - COLUMBIA', value: '55021 - COLUMBIA' },
                { name: '55023 - CRAWFORD', value: '55023 - CRAWFORD' },
                { name: '55039 - FONDDULAC', value: '55039 - FONDDULAC' },
                { name: '55043 - GRANT', value: '55043 - GRANT' },
                { name: '55045 - GREEN', value: '55045 - GREEN' },
                { name: '55049 - IOWA', value: '55049 - IOWA' },
                { name: '55055 - JEFFERSON', value: '55055 - JEFFERSON' },
                { name: '55057 - JUNEAU', value: '55057 - JUNEAU' },
                { name: '55059 - KENOSHA', value: '55059 - KENOSHA' },
                { name: '55063 - LACROSSE', value: '55063 - LACROSSE' },
                { name: '55065 - LAFAYETTE', value: '55065 - LAFAYETTE' },
                { name: '55071 - MANITOWOC', value: '55071 - MANITOWOC' },
                { name: '55081 - MONROE', value: '55081 - MONROE' },
                { name: '55103 - RICHLAND', value: '55103 - RICHLAND' },
                { name: '55105 - ROCK', value: '55105 - ROCK' },
                { name: '55111 - SAUK', value: '55111 - SAUK' },
                { name: '55123 - VERNON', value: '55123 - VERNON' },
                { name: '55127 - WALWORTH', value: '55127 - WALWORTH' },
                { name: '55133 - WAUKESHA', value: '55133 - WAUKESHA' },
            ];
        case Region.TCJ.NEBRASKA:
        case Region.TCJ.ERIE:
        case Region.TCJ.WHITEEAGLE:
        case Region.TCJ.GPDA:
        case Region.TCJ.LIBERTY:
        case Region.TCJ.KYTN:
            return [
                { name: 'Allegan', value: 'Allegan' },
                { name: 'Allen', value: 'Allen' },
                { name: 'Brookings', value: 'Brookings' },
                { name: 'Broome', value: 'Broome' },
                { name: 'Butler', value: 'Butler' },
                { name: 'Caldwell', value: 'Caldwell' },
                { name: 'Cortland', value: 'Cortland' },
                { name: 'Darke', value: 'Darke' },
                { name: 'Elkhart', value: 'Elkhart' },
                { name: 'Fulton', value: 'Fulton' },
                { name: 'Gilchrist', value: 'Gilchrist' },
                { name: 'Grant', value: 'Grant' },
                { name: 'Hanson', value: 'Hanson' },
                { name: 'Henry', value: 'Henry' },
                { name: 'Huron', value: 'Huron' },
                { name: 'Jackson', value: 'Jackson' },
                { name: 'Jay', value: 'Jay' },
                { name: 'Kalamazoo', value: 'Kalamazoo' },
                { name: 'Kosciusko', value: 'Kosciusko' },
                { name: 'Lake', value: 'Lake' },
                { name: 'LaPorte', value: 'LaPorte' },
                { name: 'Lenawee', value: 'Lenawee' },
                { name: 'Lincoln', value: 'Lincoln' },
                { name: 'Lyon', value: 'Lyon' },
                { name: 'Madison', value: 'Madison' },
                { name: 'Marshall', value: 'Marshall' },
                { name: 'McCook', value: 'McCook' },
                { name: 'Mercer', value: 'Mercer' },
                { name: 'Noble', value: 'Noble' },
                { name: 'Ottawa', value: 'Ottawa' },
                { name: 'Pipestone', value: 'Pipestone' },
                { name: 'Randolph', value: 'Randolph' },
                { name: 'Richland', value: 'Richland' },
                { name: 'St. Joseph', value: 'St. Joseph' },
                { name: 'Todd', value: 'Todd' },
                { name: 'Tompkins', value: 'Tompkins' },
                { name: 'Trigg', value: 'Trigg' },
                { name: 'Warren', value: 'Warren' },
                { name: 'Washington', value: 'Washington' },
                { name: 'WashTenaw', value: 'WashTenaw' },
                { name: 'Wayne', value: 'Wayne' },
                { name: 'Wells', value: 'Wells' },
                { name: 'Whitley', value: 'Whitley' },
                { name: 'Yellow Medicine', value: 'Yellow Medicine' },
            ];
        default:
            return [];
    }
};

export const bankNames = (region) =>
    [Region.CDI].includes(region)
        ? [
              { name: 'American Ag Credit/WFB, N.A', value: 'American Ag Credit/WFB, N.A' },
              { name: 'Bank Of America NA', value: 'Bank Of America NA' },
              { name: 'Bank Of Stockton', value: 'Bank Of Stockton' },
              { name: 'Bank Of The Sierra', value: 'Bank Of The Sierra' },
              { name: 'Bank Of The West', value: 'Bank Of The West' },
              { name: 'Central Valley Community Bank', value: 'Central Valley Community Bank' },
              { name: 'Chino Commercial Bank', value: 'Chino Commercial Bank' },
              { name: 'Citibank FSB', value: 'Citibank FSB' },
              { name: 'Citizens Business Bank', value: 'Citizens Business Bank' },
              { name: 'CoBank', value: 'CoBank' },
              { name: 'CoBank ACB', value: 'CoBank ACB' },
              { name: 'Farm Credit Services of America through Washington County Bank', value: 'Farm Credit Services of America through Washington County Bank' },
              { name: 'Farmers & Merchants Bk Of Central CA', value: 'Farmers & Merchants Bk Of Central CA' },
              { name: 'Golden State Farm Credit, PCA', value: 'Golden State Farm Credit, PCA' },
              { name: 'Great Western Bank', value: 'Great Western Bank' },
              { name: 'JPMorgan Chase Bank NA', value: 'JPMorgan Chase Bank NA' },
              { name: 'Mechanics Bank', value: 'Mechanics Bank' },
              { name: 'Mission Bank', value: 'Mission Bank' },
              { name: 'Modesto Comm Bk-a Div of Bk Stockton', value: 'Modesto Comm Bk-a Div of Bk Stockton' },
              { name: 'Oak Valley Community Bank', value: 'Oak Valley Community Bank' },
              { name: 'Tri Counties Ban', value: 'Tri Counties Ban' },
              { name: 'Tri Counties Bank', value: 'Tri Counties Bank' },
              { name: 'Umpqua Bank', value: 'Umpqua Bank' },
              { name: 'Union Bank NA', value: 'Union Bank NA' },
              { name: 'Washington County Bank', value: 'Washington County Bank' },
              { name: 'Wells Fargo Bank', value: 'Wells Fargo Bank' },
              { name: 'Wells Fargo Bank NA', value: 'Wells Fargo Bank NA' },
              { name: 'Westamerica Bank', value: 'Westamerica Bank' },
          ]
        : [];

export const haulingTypes = [
    { value: '', name: 'None' },
    { value: 'number_of_stops', name: 'Number of Stops' },
    { value: 'amount_per_stop', name: 'Amount per Stop' },
    { value: 'limit_by_days', name: 'Limit by Days' },
];

export const status = [
    { name: 'Active', value: 'Active' },
    { name: 'Inactive', value: 'Inactive' },
];

export const milkCategory = [
    { name: 'Outside Milk', value: 'outside_milk' },
    { name: 'Member Producer', value: 'member_producer' },
];

export const regionStateList = (region) => {
    switch (region) {
        case Region.PRAIRIE:
            return [
                { name: 'FL', value: 'FL' },
                { name: 'IA', value: 'IA' },
                { name: 'IL', value: 'IL' },
                { name: 'IN', value: 'IN' },
                { name: 'KS', value: 'KS' },
                { name: 'KY', value: 'KY' },
                { name: 'MI', value: 'MI' },
                { name: 'MN', value: 'MN' },
                { name: 'MO', value: 'MO' },
                { name: 'NM', value: 'NM' },
                { name: 'OH', value: 'OH' },
                { name: 'TN', value: 'TN' },
                { name: 'TX', value: 'TX' },
                { name: 'VA', value: 'VA' },
                { name: 'WI', value: 'WI' },
            ];
        case Region.MMPA:
            return [
                { name: 'AL', value: 'AL' },
                { name: 'AK', value: 'AK' },
                { name: 'AZ', value: 'AZ' },
                { name: 'AR', value: 'AR' },
                { name: 'AS', value: 'AS' },
                { name: 'CA', value: 'CA' },
                { name: 'CO', value: 'CO' },
                { name: 'CT', value: 'CT' },
                { name: 'DE', value: 'DE' },
                { name: 'DC', value: 'DC' },
                { name: 'FL', value: 'FL' },
                { name: 'GA', value: 'GA' },
                { name: 'GU', value: 'GU' },
                { name: 'HI', value: 'HI' },
                { name: 'ID', value: 'ID' },
                { name: 'IL', value: 'IL' },
                { name: 'IN', value: 'IN' },
                { name: 'IA', value: 'IA' },
                { name: 'KS', value: 'KS' },
                { name: 'KY', value: 'KY' },
                { name: 'LA', value: 'LA' },
                { name: 'ME', value: 'ME' },
                { name: 'MD', value: 'MD' },
                { name: 'MA', value: 'MA' },
                { name: 'MI', value: 'MI' },
                { name: 'MN', value: 'MN' },
                { name: 'MS', value: 'MS' },
                { name: 'MO', value: 'MO' },
                { name: 'MT', value: 'MT' },
                { name: 'NE', value: 'NE' },
                { name: 'NV', value: 'NV' },
                { name: 'NH', value: 'NH' },
                { name: 'NJ', value: 'NJ' },
                { name: 'NM', value: 'NM' },
                { name: 'NY', value: 'NY' },
                { name: 'NC', value: 'NC' },
                { name: 'ND', value: 'ND' },
                { name: 'MP', value: 'MP' },
                { name: 'OH', value: 'OH' },
                { name: 'OK', value: 'OK' },
                { name: 'OR', value: 'OR' },
                { name: 'PA', value: 'PA' },
                { name: 'PR', value: 'PR' },
                { name: 'RI', value: 'RI' },
                { name: 'SC', value: 'SC' },
                { name: 'SD', value: 'SD' },
                { name: 'TN', value: 'TN' },
                { name: 'TX', value: 'TX' },
                { name: 'TT', value: 'TT' },
                { name: 'UT', value: 'UT' },
                { name: 'VT', value: 'VT' },
                { name: 'VA', value: 'VA' },
                { name: 'VI', value: 'VI' },
                { name: 'WA', value: 'WA' },
                { name: 'WV', value: 'WV' },
                { name: 'WI', value: 'WI' },
                { name: 'WY', value: 'WY' },
            ];
        default:
            return [];
    }
};
export const selfHaul = [
    { name: 'Yes', value: true },
    { name: 'No', value: false },
];

export const pickupType = (region) => {
    return [
        { name: 'Estimate', value: [Region.MMPA, Region.UNC].includes(region) ? 'estimate' : 'metered' },
        { name: 'Scale', value: 'scale' },
        { name: 'Stick', value: 'dip' },
        { name: 'Sight Tube', value: 'tube' },
    ];
};

export const outOfBusiness = [
    { value: 'changed_markets', name: 'Changed Markets' },
    { value: 'combined_herds', name: 'Combined Herds' },
    { value: 'out_of_business', name: 'Out of Business' },
    { value: 'resignation', name: 'Resignation' },
    { value: 'retired', name: 'Retired' },
    { value: 'sold_herd', name: 'Sold Herd' },
    { value: 'other', name: 'Other' },
];

export const farmType = [
    { name: 'Large Farm', value: 'large_farm' },
    { name: 'Multi Farm', value: 'multi_farm' },
];

export const pumpType = [
    { name: 'Direct Load (DL)', value: 'direct_load' },
    { name: 'High Speed (HS)', value: 'high_speed' },
    { name: 'Regular (RG)', value: 'regular' },
];

export const getRouteRegionLabel = (region) => {
    switch (region) {
        case Region.CDI:
            return 'District';
        case Region.PRAIRIE:
            return 'District ID';
        case Region.UNC:
            return 'Sub Market';
        case Region.MMPA:
        default:
            return 'Territory';
    }
};

const mmpaPremiumPrograms = [
    { program_name: 'ohio_premium_program', program_group: 'WO', name: 'Ohio Premium Program - WO' },
    { program_name: 'ohio_premium_program', program_group: 'SL', name: 'Ohio Premium Program - SL' },
    { program_name: 'ohio_premium_program', program_group: 'SR', name: 'Ohio Premium Program - SR' },
];

export const getPremiumProgramOptions = (region) => {
    switch (region) {
        case Region.MMPA:
            return mmpaPremiumPrograms;
        default:
            return [];
    }
};

export const payTransactionDateBasisOptions = [
    { name: 'Pickup Date', value: 'pickup_date' },
    { name: 'Dropoff Date', value: 'dropoff_date' },
];
