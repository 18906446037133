import axios from 'axios';
import { bulkDeleteScheduleFluidItemQuery } from './queries';

const bulkDeleteScheduleFluidItem = async (keys) => {
    try {
        const query = bulkDeleteScheduleFluidItemQuery({ keys });
        const request = axios.delete(query.url, { data: { ...query.body } });
        const { data } = await request;
        return { ids: data };
    } catch {
        throw new Error('Unable to delete schedule item');
    }
};

export default bulkDeleteScheduleFluidItem;
