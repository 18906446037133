import Environment from '../../Environment';
import stages from '../stages';
import MockSplit from '../../MockSplit';
import Region from '../../../constants/region';

const prod = new Environment({
    name: stages.PRODUCTION,
    evaluationFn: (attributes) => {
        const { region } = attributes;
        const allowedRegions = [Region.UNC, Region.DEMO];
        if (!allowedRegions.includes(region)) return 'off';
        return 'on';
    },
});

const demo = new Environment({
    ...prod,
    name: stages.DEMO,
});

const staging = new Environment({
    name: stages.STAGING,
    evaluationFn: (_attributes) => {
        return 'on';
    },
});

const local = new Environment({
    ...staging,
    name: stages.LOCAL,
});

const dev = new Environment({
    ...prod,
    name: stages.DEV,
});

const use_list_view_schedule = new MockSplit({
    name: 'use_list_view_schedule',
    environments: [local, dev, staging, demo, prod],
});

export default use_list_view_schedule;
