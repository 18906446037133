import Region from '../../../constants/region';
import Environment from '../../Environment';
import MockSplit from '../../MockSplit';
import stages from '../stages';

/**
 * Show generate report options on production in all regions except CDI, which
 * should only be visible on staging until customer feedback is received.
 */

const prod = new Environment({
    name: stages.PRODUCTION,
    evaluationFn: (attributes) => {
        return attributes.region !== Region.CDI ? 'on' : 'off';
    },
});

const demo = new Environment({
    ...prod,
    name: stages.DEMO,
});

const staging = new Environment({
    evaluationFn: (attributes) => {
        return 'on';
    },
    name: stages.STAGING,
});

const local = new Environment({
    ...staging,
    name: stages.LOCAL,
});

const dev = new Environment({
    ...staging,
    name: stages.DEV,
});

const dark_launch_pf_rs_report_options = new MockSplit({
    name: 'dark_launch_pf_rs_report_options',
    environments: [local, dev, staging, demo, prod],
});

export default dark_launch_pf_rs_report_options;
