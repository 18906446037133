import axios from 'axios';
import { getLoanPaymentsQuery } from '../queries';
import parseError from '../errors/utils';

const getExtraLoanPayments = async ({ startDate, endDate }) => {
    try {
        const loanPayments = await axios.get(getLoanPaymentsQuery({ startDate, endDate }));
        return { loanPayments: loanPayments?.data?.filter(({ payment }) => payment?.payer_payment_report_id == null) };
    } catch (error) {
        return { error: parseError({ error }) };
    }
};

export default getExtraLoanPayments;
