import Environment from '../../Environment';
import MockSplit from '../../MockSplit';
import stages from '../stages';
import { ALL_REGIONS } from '../../../constants/bulkRegion';

const ALLOWED_REGIONS_PROD = ALL_REGIONS;
const ALLOWED_REGIONS_TEST = ALL_REGIONS;

const prod = new Environment({
    name: stages.PRODUCTION,
    regions: ALLOWED_REGIONS_PROD,
    evaluationFn: (attributes) => {
        return 'on';
    },
});

const demo = new Environment({
    name: stages.DEMO,
    regions: ALLOWED_REGIONS_PROD,
    evaluationFn: (attributes) => {
        return 'on';
    },
});

const staging = new Environment({
    name: stages.STAGING,
    regions: ALLOWED_REGIONS_TEST,
    evaluationFn: (attributes) => {
        return 'on';
    },
});

const local = new Environment({
    ...staging,
    regions: ALLOWED_REGIONS_TEST,
    name: stages.LOCAL,
});

const dev = new Environment({
    ...staging,
    regions: ALLOWED_REGIONS_TEST,
    name: stages.DEV,
});

/**
 * Create the new MockSplit, be sure to create the env dictionary with util
 */
const use_new_lab_form = new MockSplit({
    name: 'use_new_lab_form',
    environments: [local, dev, staging, demo, prod],
});

export default use_new_lab_form;
