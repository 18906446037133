import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, CircularProgress, Modal, Button, Typography } from '@mui/material';
import { getFileURL } from '../../repository';

const cardSx = {
    position: 'absolute',
    maxWidth: '45vw',
    maxHeight: '92vh',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    padding: (theme) => theme.spacing(2),
};

function ViewImageModal({ fileName, open, handleClose }) {
    const [imageUrl, setImageUrl] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    const fetchFileUrl = async () => {
        setLoading(true);

        try {
            const url = await getFileURL(fileName, false, true);

            setImageUrl(url);
        } catch (err) {
            setError(err.message || 'There was an error retrieving the image. Please contact support if this issue persists.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFileUrl();
    }, []);

    if (loading) return <CircularProgress />;

    return (
        <Modal open={open} close={handleClose}>
            <Box display="flex" alignItems="center" justifyContent="center" fullWidth>
                <Card sx={cardSx}>
                    <CardContent>
                        {error ? (
                            <Box m={2}>
                                <Typography variant="h6">{error}</Typography>
                            </Box>
                        ) : (
                            imageUrl && <img style={{ width: '100%' }} src={imageUrl} alt="Not found" />
                        )}
                        <Box display="flex" justifyContent="center" mt={1}>
                            <Button variant="outlined" onClick={handleClose}>
                                Close
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Modal>
    );
}

export default ViewImageModal;
