import Region from '../../../constants/region';
import Environment from '../../Environment';
import MockSplit from '../../MockSplit';
import stages from '../stages';

/**
 * Define all of your environment configs here
 */

const local = new Environment({
    name: stages.LOCAL,
    regions: [Region.CDI],
});

const dev = new Environment({
    name: stages.DEV,
    regions: [Region.CDI],
});

const stg = new Environment({
    name: stages.STAGING,
    regions: [Region.CDI],
});

const prod = new Environment({
    name: stages.PRODUCTION,
    regions: [],
});

/**
 * Create the new MockSplit, be sure to create the env dictionary with util
 */
const can_access_cdi_quality_report_producer = new MockSplit({
    name: 'can_access_cdi_quality_report_producer',
    environments: [local, dev, stg, prod],
});

export default can_access_cdi_quality_report_producer;
