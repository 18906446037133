import React from 'react';
import { Paper, Grid, Button, Box } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';

const DataGridPaperSx = {
    width: '100%',
    marginBottom: (theme) => theme.spacing(12),
};
const ScrollFixPaperSx = (height = '65vh') => ({
    width: '100%',
    '.MuiDataGrid-virtualScroller': {
        maxHeight: height,
        overflowY: 'scroll !important',
    },
});
const DataGridSx = {
    '.MuiDataGrid-columnSeparator': {
        display: 'none',
    },
};
const LoadMoreSx = {
    padding: (theme) => theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
};

/**
 * Wraps the table to help fix scrolling on wider tables
 * @param {string} height - The height of the scrollable box (default 100vh)
 * @returns
 */
export const ScrollFix = ({ children, height }) => {
    return <Box sx={ScrollFixPaperSx(height)}>{children}</Box>;
};

// TODO: Should `columns` and `rows` just be in `...rest`?
// TODO: Error messaging
/**
 * If overriding the componentProps, toolbarProps will be overridden as well.
 * Pass in toolbar object in componentProps object.
 * @param {object} props
 * @param {object[]} props.columns - The columns to display in the table
 * @param {object[]} props.rows - The rows to display in the table
 * @param {Function} props.onLoadMore - The function to call when the user clicks the Load More button
 * @param {object} props.toolbarProps - The props to pass to the toolbar
 * @param {object} props.PaperSx - The props to pass to the Paper component
 *
 */
const Table = ({ columns, rows, onLoadMore, toolbarProps, PaperSx, PaperProps, ...rest }) => (
    <Paper sx={{ ...DataGridPaperSx, ...PaperSx }} {...PaperProps}>
        <div style={{ width: '100%' }}>
            <DataGridPro
                sx={DataGridSx}
                rows={rows}
                columns={columns}
                autoHeight
                // TODO: Should this be tied in to rest.selectable?
                hideFooterSelectedRowCount={rest.hideFooterSelectedRowCount ?? true}
                // Props sent in to table will override the props above
                {...rest}
                // wrap
            />
        </div>
        {!!onLoadMore && (
            <Grid container sx={LoadMoreSx}>
                <Button variant="contained" onClick={onLoadMore}>
                    Load More
                </Button>
            </Grid>
        )}
    </Paper>
);

export default Table;
