import Region from '../../../constants/region';
import Environment from '../../Environment';
import MockSplit from '../../MockSplit';
import stages from '../stages';

const NOT_ALLOWED_REGIONS_PROD = [Region.CDI, Region.LEGACY, Region.NL, Region.PEI, Region.RF];

const prod = new Environment({
    name: stages.PRODUCTION,
    regions: NOT_ALLOWED_REGIONS_PROD,
});

const demo = new Environment({
    ...prod,
    name: stages.DEMO,
});

const staging = new Environment({
    ...prod,
    name: stages.STAGING,
});

const local = new Environment({
    ...prod,
    name: stages.LOCAL,
});

const dev = new Environment({
    ...prod,
    name: stages.DEV,
});

/**
 * Create the new MockSplit, be sure to create the env dictionary with util
 */
const use_legacy_processor_form = new MockSplit({
    name: 'use_legacy_processor_form',
    environments: [local, dev, staging, demo, prod],
});

export default use_legacy_processor_form;
