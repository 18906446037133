import { REGION } from '../../../constants';
import Environment from '../../Environment';
import MockSplit from '../../MockSplit';
import stages from '../stages';

/**
 * Allow different regions to be able try different pickup queries
 */

const prod = new Environment({
    name: stages.PRODUCTION,
    regions: [],
    evaluationFn: (attributes) => {
        if ([REGION.MMPA].includes(attributes.region)) return 'on';
        return 'off';
    },
});

const local = new Environment({
    ...prod,
    name: stages.LOCAL,
    evaluationFn: (attributes) => {
        if ([REGION.MMPA].includes(attributes.region)) return 'on';
        return 'off';
    },
});

const dev = new Environment({
    ...prod,
    name: stages.DEV,
    regions: [REGION.MMPA],
    evaluationFn: (attributes) => {
        if ([REGION.MMPA].includes(attributes.region)) return 'on';
        return 'off';
    },
});

const staging = new Environment({
    ...prod,
    name: stages.STAGING,
    evaluationFn: (attributes) => {
        if ([REGION.MMPA].includes(attributes.region)) return 'on';
        return 'off';
    },
});

const demo = new Environment({
    ...prod,
    name: stages.DEMO,
    regions: [],
});

const can_test_new_recurring_workflows = new MockSplit({
    name: 'can_test_new_recurring_workflows',
    environments: [local, dev, staging, demo, prod],
});

export default can_test_new_recurring_workflows;
