import { getUserRegion } from '../../../../../utils';
import Region from '../../../../constants/region';
import config from './dummy-config';

const configuredNavigation = {
    BONGARDS: config.bongardsConfig,
    CACIQUE: config.caciqueConfig,
    CDI: config.cdiConfig,
    DEFAULT: config.defaultConfig,
    LEGACY: config.legacyConfig,
    MMPA: config.mmpaConfig,
    NL: config.nlConfig,
    PEI: config.peiConfig,
    PRAIRIE: config.prairieConfig,
    RF: config.rfConfig,
    [Region.TCJ.NEBRASKA]: config.tcjConfig,
    [Region.TCJ.LIBERTY]: config.tcjConfig,
    [Region.TCJ.WHITEEAGLE]: config.tcjConfig,
    [Region.TCJ.ERIE]: config.tcjConfig,
    [Region.TCJ.KYTN]: config.tcjConfig,
    [Region.TCJ.GPDA]: config.tcjConfig,
    UDA: config.udaConfig,
    UNC: config.uncConfig,
    [Region.US_DEMO]: config.usDemoConfig,
    [Region.CA_DEMO]: config.usDemoConfig,
    [Region.DEMO]: config.usDemoConfig,
    [Region.PSC]: config.pscConfig,
};

export default configuredNavigation;

export const getNavigationConfig = () => {
    const region = getUserRegion();
    return Object.values(configuredNavigation[region] ?? configuredNavigation.DEFAULT);
};
