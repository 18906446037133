import caciqueConfig from './cacique-config';
import cdiConfig from './cdi-config';
import defaultConfig from './default-config';
import legacyConfig from './legacy-config';
import mmpaConfig from './mmpa-config';
import nlConfig from './nl-config';
import peiConfig from './pei-config';
import prairieConfig from './prairie-config';
import rfConfig from './rf-config';
import tcjConfig from './tcj-config';
import udaConfig from './uda-config';
import uncConfig from './unc-config';
import usDemoConfig from './us-demo-config';
import bongardsConfig from './bongards-config';
import pscConfig from './psc-config';

export default {
    caciqueConfig,
    cdiConfig,
    defaultConfig,
    legacyConfig,
    mmpaConfig,
    nlConfig,
    peiConfig,
    prairieConfig,
    rfConfig,
    tcjConfig,
    udaConfig,
    uncConfig,
    usDemoConfig,
    bongardsConfig,
    pscConfig,
};
