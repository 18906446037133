import moment from 'moment-timezone';
import _ from 'lodash';
import axios from 'axios';
import Region from '../../constants/region';
import { getExpressAPIHost, getUser } from '../../../utils';
import { isLTValid, isStringInList, uniqueError, validateError } from '../csv-upload/UploadCSV.helpers';
import { getCommodities } from '../../main/apps/Core/scheduling2/fluid/commodities';
import { getUserDisplayName, getUserLicenseNumber } from '../../helpers/users/utils';

const processedFluidTypesList = [];

export const propertyToString = (commodities, property) => {
    return commodities[property] || null;
};

export const stringToProperty = (commodities, property) => {
    return _.invert(commodities)[property] || null;
};

export const filterById = (array) => array.filter((item) => item.id !== '');

export const mapValuesById = (ids, groupMap) => ids.map((id) => groupMap[id]);

export const getProcessedFluidTypesList = (commodities) => {
    if (processedFluidTypesList.length > 0) return processedFluidTypesList;
    const items = Object.keys(commodities).map((k) => ({
        value: k,
        name: commodities[k],
    }));
    processedFluidTypesList.push(...items);
    return processedFluidTypesList;
};

export function toAmountString(amount) {
    if (!amount?.toString()) return 0;
    return `${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}

export function toTimeString(timeInMs) {
    const hours = Math.floor(timeInMs / (1000 * 60 * 60));
    const minutes = Math.floor(timeInMs / (1000 * 60)) % 60;
    const seconds = (Math.floor(timeInMs / 1000) % 60) % 60;
    const hh = !!hours ? hours.toString() : '';
    const mm = !!hh ? minutes.toString().padStart(2, '0') : minutes.toString();
    const ss = seconds.toString().padStart(2, '0');
    return `${!!hh ? `${hh}:` : ''}${mm}:${ss}`;
}

export function getDateArray(date) {
    return [...Array(7).keys()].map((i) => date.clone().add(i, 'days'));
}

export function getDataMap(data, demand) {
    return demand ? data.demandItemDateMap : data.supplyItemDateMap;
}

export function getProcessors(data, processorIds) {
    if (processorIds.length === 0) return data.processors;
    return processorIds.map((pId) => data.processorMap[pId]);
}

export function getHaulingCompanies(data, haulingIds) {
    if (haulingIds.length === 0) return data.haulingCompanies;
    return haulingIds.map((hId) => data.haulingMap[hId]);
}

export function getMaxSingleDayItems(data, currentDay) {
    return 1;
}

export function getMaxItems(dataMap) {
    return Math.max(...Object.values(dataMap).map((value) => (value.items ? value.items.length : 0)));
}

export function filterSearch(items, search, field) {
    if (search === '') return items;
    return items.filter((item) => item[field]?.name?.toLowerCase().includes(search.toLowerCase()));
}

export function isPastDate(date, type) {
    const { region } = getUser();
    const dayOffsets = {
        [Region.PRAIRIE]: 6,
        [Region.UDA]: type === 'fluid' ? 45 : 1,
        default: 1,
    };

    const offset = dayOffsets[region] ?? dayOffsets.default;
    const referenceDate = moment().subtract(offset, 'day');

    return moment.isMoment(date) ? date.isBefore(referenceDate, 'day') : moment(date, 'YYYY-MM-DD').isBefore(referenceDate, 'day');
}

export const internalUserStructToSearchableSelectStruct = (userSet) =>
    userSet.map((u) => {
        const license_number = getUserLicenseNumber(u);
        const name = getUserDisplayName(u.name, license_number, false);
        return { name, value: u.id };
    });

export const RawMilkCSVConfig = (routes, processors) => ({
    headers: [
        {
            name: 'LT Number',
            inputName: 'lt_number',
            required: true,
            unique: true,
            uniqueError,
            validate: isLTValid,
            validateError,
        },
        {
            name: 'Route',
            inputName: 'route_name',
            required: true,
            validate: (value) => isStringInList(value, routes),
            validateError,
        },
        {
            name: 'Processor',
            inputName: 'processor_name',
            required: true,
            validate: (value) => isStringInList(value, processors),
            validateError,
        },
    ],
});

export const ProcessedFluidCSVConfig = () => {
    const { region } = getUser();
    const commodities = Object.keys(getCommodities(region));
    return {
        headers: [
            {
                name: 'Hauler Pickup Time',
                inputName: 'start_date',
                required: true,
                // validate: (value) => moment(value, 'M/DD/YY HH:mm', true).isValid(),
                validate: (value) => moment(value, 'M/D/YY H:mm', true).isValid(),
                validateError,
            },
            {
                name: 'Customer Delivery Time',
                inputName: 'date',
                required: true,
                validate: (value) => moment(value, 'M/D/YY H:mm', true).isValid(),
                validateError,
            },
            {
                name: 'Plant Load Out Time',
                inputName: 'pickup_ready_time',
                required: true,
                validate: (value) => moment(value, 'M/D/YY H:mm', true).isValid(),
                validateError,
            },
            {
                name: 'Amount',
                inputName: 'amount',
                required: true,
                validateError,
            },
            {
                name: 'Commodity',
                inputName: 'commodity_type',
                required: true,
                validate: (value) => commodities.includes(value.toLowerCase()),
                validateError,
            },
            {
                name: 'Destination Processor',
                inputName: 'destination_processor_name',
                required: true,
            },
            {
                name: 'Origin Processor',
                inputName: 'origin_processor_name',
                required: true,
            },
            {
                name: 'Hauling',
                inputName: 'hauler_name',
                required: true,
            },
            {
                name: 'LT Number',
                inputName: 'lt_number',
                validate: isLTValid,
                validateError,
                // Validation will be done entirely on backend
            },
            ...(region === Region.UDA
                ? [
                      {
                          name: 'PO Number',
                          inputName: 'po_number',
                      },
                      {
                          name: 'Manifest Number',
                          inputName: 'manifest_number',
                      },
                  ]
                : []),
        ],
    };
};

export const downloadSchedulingCSVTemplate = (csvConfig, isProcessedFluids) => {
    const csvHeaders = csvConfig.headers.map((header) => header.name).join(',');

    const blob = new Blob([`${csvHeaders}\n`], { type: 'text/csv' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${isProcessedFluids ? 'Processed_Fluids' : 'Raw_Milk'}_Schedule_Template.csv`;

    link.click();
    URL.revokeObjectURL(link.href);
};

export const submitScheduleCSV = async (endpoint, formData) => {
    try {
        const response = await axios({
            method: 'post',
            url: `${getExpressAPIHost()}${endpoint}`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        return response;
    } catch (error) {
        return { error: error?.response?.data?.message || 'Unable to upload CSV.' };
    }
};

export const getDateString = (start, end) => {
    const sameMonth = start.isSame(end, 'month');
    const sameYear = start.isSame(end, 'year');

    const startMonth = start.format('MMMM');
    const startYear = start.format('YYYY');
    const endMonthYear = end.format('MMMM YYYY');

    return sameMonth ? `${startMonth} ${startYear}` : `${startMonth}${sameYear ? '' : ` ${startYear}`} - ${endMonthYear}`;
};

export const milkCategories = [
    { name: 'Member Producer', value: 'member_producer' },
    { name: 'Outside Milk', value: 'outside_milk' },
    { name: 'Co-mingled', value: 'comingled' },
];

export const filterCategoryOptions = (mode) => {
    if (!mode) {
        return milkCategories;
    }

    return milkCategories.filter((option) => option.value === mode.replace('-', '_') || option.value === 'comingled');
};

export const getInitialCategoryValue = (mode) => {
    return milkCategories.filter((option) => option.value === mode.replace('-', '_'));
};

export const filterCategoriesByValue = (selectedCategories) => {
    return milkCategories.filter((milkCategory) => selectedCategories.some((selected) => selected.value === milkCategory.value));
};
